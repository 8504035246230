import React, { useState, useEffect } from "react";
import { Div, Card, Avatar, Text, H6, Image, Divider } from "qdm-component-library";
import { useSelector } from "react-redux";
import { calculateAge, getImgUrl } from "../../../utils";
import { UIColor } from "../../../themes/theme";
import LoopIcon from "@material-ui/icons/Loop";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Tooltip, IconButton } from "@material-ui/core";
import IdentificationDocuments from "../../../assets/img/svg/icons8-identification-documents.svg";
import styles from "./styles";
import moment from "moment";
import Outstanding from "./outstanding";
import groupIcon from "../../../assets/img/svg/Group 90503.svg";
import phoneIcon from "../../../assets/img/svg/icons8-phone.svg";
import Wallet from "../../../components/wallet";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import { useLocation } from "react-router-dom";

function PatientCard(props) {
const dispatch = useDispatch();
const location = useLocation();
  // ;
  //   const { loading, data } = useSelector(
  //     (state) => state?.patientReducer?.PatientDetails
  //   );
  const loading = false;
  const data = [
    {
      PractitionerID: [],
      _id: "Patient/11096",
      _key: "11096",
      _rev: "_gkTRo1m--m",
      active: true,
      activestatus: true,
      address: [],
      age: "35",
      age_type: {
        _key: "10241",
        _id: "CodingMaster/10241",
        _rev: "_e8UTuaO--m",
        Type: "VITALSUOM",
        activestatus: true,
        code: "Y",
        createdby: "",
        createddate: 1635943903,
        display: "Years",
        gmconfigvalues: {},
        id: 241,
        shortdesc: "Years",
        status: true,
        updatedate: 1635943903,
        updatedby: "",
      },
      alias: "MRN00_942",
      approxAgeEntered: false,
      attachment: [],
      birthDate: "01/07/1988",
      birthPlace: "",
      birthTime: 0,
      cadavericDonor: false,
      communicationLanguage: [],
      conditionhistory: [],
      createdby: "",
      createddate: 1689740781,
      deceaseddate: 0,
      gender: {
        _key: "10267",
        _id: "CodingMaster/10267",
        _rev: "_fDC8n52---",
        Type: "GENDER",
        activestatus: true,
        code: "MALE",
        collection_name: "CodingMaster",
        createdby: "",
        createddate: 1667810371,
        display: "MALE",
        gmconfigvalues: {},
        id: 267,
        shortdesc: "MALE",
        status: true,
        updatedate: 1667810371,
        updatedby: "",
      },
      id: 1058,
      identifier: [
        {
          Type: "",
          _id: "IdentifierMaster/14758",
          _key: "14758",
          _rev: "_gUvkH66---",
          activestatus: true,
          assigner: {
            _key: "11218",
            _id: "CodingMaster/11218",
            _rev: "_e8UTua2-_I",
            Type: "RELATIONSHIP",
            activestatus: true,
            code: "RE011",
            collection_name: "CodingMaster",
            createdby: "",
            createddate: 1656496507,
            display: "Self",
            gmconfigvalues: {},
            id: 1204,
            shortdesc: "Self",
            status: true,
            updatedate: 1656496507,
            updatedby: "",
          },
          createdby: "",
          createddate: 1689740781,
          files: [],
          id: 4263,
          period: [
            {
              _key: "55909",
              _id: "periodMaster/55909",
              _rev: "_gUvkH62--C",
              activestatus: true,
              createdby: "",
              createddate: 1689740781,
              end: 0,
              id: 0,
              start: 1689740779,
              updatedate: 1689740781,
              updatedby: "",
            },
          ],
          system: "",
          updatedate: 1689740781,
          updatedby: "",
          user: {
            _key: "10520",
            _id: "CodingMaster/10520",
            _rev: "_e8UTuaO-_Y",
            Type: "ALTERNATEIDTYPE",
            activestatus: true,
            code: "AD006",
            createdby: "",
            createddate: 1635954244,
            display: "MyKad",
            gmconfigvalues: {},
            id: 520,
            shortdesc: "MyKad",
            status: true,
            updatedate: 1635954244,
            updatedby: "",
          },
          value: "4354543543435435",
        },
      ],
      link: [
        {
          patient: [],
          Type: "",
        },
      ],
      name: [
        {
          _id: "HumanNameMaster/14616",
          _key: "14616",
          _rev: "_gUvkH62--A",
          activestatus: true,
          createdby: "",
          createddate: 1689740781,
          family: "",
          given: "",
          id: 3845,
          period: [],
          text: "raji",
          updatedate: 1689740781,
          updatedby: "",
        },
      ],
      nationality: {
        _key: "10000",
        _id: "SMGeographicMaster/10000",
        _rev: "_gf2NK7y---",
        GeoLocationISDCode: "+91",
        active: false,
        activestatus: true,
        createdby: "",
        createddate: 1638447402,
        geogLevelCode: "IN / IND",
        geogLevelName: "India",
        geogLevelType: "CodingMaster/10731",
        id: 1,
        parentGeogLevelCode: "",
        parentGeogLevelType: "CodingMaster/10731",
        updatedate: 1692721427,
        updatedby: "",
      },
      patientImp_Code: "",
      personID: 1324,
      photo: [],
      preferredLanguage: "",
      residencyStatus: false,
      statushistory: [
        {
          oldstatus: "",
          oldstatusreason: "",
          newstatus: "Active",
          fromperiod: 1689740779,
          toperiod: 0,
        },
      ],
      telecom: [
        {
          _id: "ContactPointMaster/18893",
          _key: "18893",
          _rev: "_gkTUQUq--m",
          activestatus: true,
          createdby: "",
          createddate: 1689740781,
          id: 6259,
          system: {
            _key: "10473",
            _id: "CodingMaster/10473",
            _rev: "_e8UTuai--F",
            Type: "CONTACTSYSTEM",
            activestatus: true,
            code: "phone",
            createdby: "",
            createddate: 1635951134,
            display: "Phone",
            gmconfigvalues: {},
            id: 473,
            shortdesc: "Phone",
            status: true,
            updatedate: 1635951134,
            updatedby: "",
          },
          updatedate: 1689740781,
          updatedby: "",
          value: "+917878787876",
        },
      ],
      updatedate: 1689740781,
      updatedby: "",
    },
  ];

  const [state, setState] = useState({
    callcen: false,
  });
  const [personData, setPersonData] = useState({});
  const getPatientInfo = async () => {
    let data = await dispatch(
      actions.PATIENT_INFO({
        patientid: location.state?.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
        encounterid:"",
      })
    );
    data = data?.payload?.data;
    setPersonData(data?.patientdetails);
  };
  const {
    rowFlex,
    columnFlex,
    h6,
    span,
    cardStyle,
    avatar,
    img,
    status,
    status1,
    dot,
    editIcon,
    inactiveDot,
    inHoldDot,
    status2,
    cardStyle1
  } = styles;

  const name = props?.newPatientDetails?.name?.[0].fulname || personData?.name?.[0].fulname;
  const gender = props?.newPatientDetails?.gender?.display || personData?.gender?.display;
  const mrn = props?.newPatientDetails?.patmrn || personData?.patmrn;
  //const name = "panneer"
  const {
    //name = "# ---",
    pid = "PID ---",
    //mrn = "ID ---",
    //gender = "gen",
    imgUrl,
  } = props?.patientDetails || personData || {};
  const {
    addMember = () => null,
    isAddBtn = true,
    memberList = [],
    parent_id,
    permissionData,
  } = props;

  let imgageUrl = null;

  if (imgUrl) {
    imgageUrl = getImgUrl(imgUrl);
  }

  const handleEditForm = () => {
    props.handleEdit(true);
  };
  useEffect(() => {
    getPatientInfo();
  }, [props?.isPatientLogin]);
  console.log(personData);
  
  return (
    <React.Fragment>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
    {props?.isPatientLogin ?
      <Card id={`${parent_id}_patientcard_parent_card`} style={cardStyle1} >
      <div style={rowFlex}>
        <Div id={`${parent_id}_patientcard_parent_div`} style={rowFlex}>
          {props?.newPatientDetails?.photo[0]?.url ? (
            <Avatar
              id={`${parent_id}_patientcard_avatar_one`}
              src={props?.newPatientDetails?.photo[0]?.url}
              variant="rounded"
              inLineStyles={avatar}
            />
          ) : imgageUrl ? (
            <Avatar
              id={`${parent_id}_patientcard_avatar_one`}
              src={imgageUrl}
              variant="rounded"
              inLineStyles={avatar}
            />
          ) : (
            <Avatar
              id={`${parent_id}_${name?.[0]}_patientcard_avatar`}
              variant="rounded"
              inLineStyles={avatar}
            >
              {name?.[0] || props.nameDetails?.[0]}
            </Avatar>
          )}
          <Div id={`${parent_id}_patientcard_sub_div`} style={columnFlex}>
            <Text id={`${parent_id}_patientcard_text`} style={rowFlex}>
              <H6
                id={`${parent_id}_${name}_patientard_name_h6`}
                className="pc_semibold"
                style={h6}
              >
                {props.nameDetails || name}
              </H6>
              </Text>
          </Div>
        </Div>
      </div>
    </Card>
    :
      <Card id={`${parent_id}_patientcard_parent_card`} style={cardStyle}>
        <div style={rowFlex}>
          <Div id={`${parent_id}_patientcard_parent_div`} style={rowFlex}>
            {props?.newPatientDetails?.photo[0]?.url ? (
              <Avatar
                id={`${parent_id}_patientcard_avatar_one`}
                src={props?.newPatientDetails?.photo[0]?.url}
                variant="rounded"
                inLineStyles={avatar}
              />
            ) : imgageUrl ? (
              <Avatar
                id={`${parent_id}_patientcard_avatar_one`}
                src={imgageUrl}
                variant="rounded"
                inLineStyles={avatar}
              />
            ) : (
              <Avatar
                id={`${parent_id}_${name?.[0]}_patientcard_avatar`}
                variant="rounded"
                inLineStyles={avatar}
              >
                {name?.[0] || props.nameDetails?.[0]}
              </Avatar>
            )}
            <Div id={`${parent_id}_patientcard_sub_div`} style={columnFlex}>
              <Text id={`${parent_id}_patientcard_text`} style={rowFlex}>
                <H6
                  id={`${parent_id}_${name}_patientard_name_h6`}
                  className="pc_semibold"
                  style={h6}
                >
                  {props.nameDetails || name}
                </H6>
                <Text
                  id={`${parent_id}_patientcard_dot_text`}
                  className="pc_regular"
                  style={{ ...span, margin: "0px 5px" }}
                >
                  |
                </Text>
                <Text
                  id={`${parent_id}_patientinfocard_gender_text`}
                  className="pc_regular"
                  style={span}
                >
                  {props?.age + ", " + gender}
                </Text>
                {!loading &&
                  data.length > 0 &&
                  permissionData?.write?.indexOf("patientBannerStatus") >
                    -1 && (
                    <Div
                      style={
                        props.activeData
                          ? status1
                          : props?.active?.display === "active"
                          ? status
                          : props?.active?.display === "hold" || "alert"
                            ? status2
                          : status1
                      }
                    >
                      <div
                        style={
                          props.activeData
                            ? inactiveDot
                            : props?.active?.display === "active"
                            ? dot
                            : props?.active?.display === "hold" || "alert"
                            ? inHoldDot
                            : inactiveDot
                        }
                      ></div>
                      {props.activeData
                        ? props.activeData
                        : props.active
                        ? props?.active?.display
                        : data?.[0]?.activereason?._key
                        ? "Inactive"
                        : "Deseased"}
                    </Div>
                  )}
              </Text>
              <Div
                id={`${parent_id}_patientcard_doc_div`}
                style={{ ...rowFlex, marginTop: 7 }}
              >
                {props.newPatientDetails?.name?.length > 0 && (
                  <Div style={{ display: "flex" }}>
                    <Div
                      id={`${parent_id}_patientcard_doc_sub_div`}
                      style={{ ...rowFlex, marginRight: 15 }}
                    >
                      <Image
                        id={`${parent_id}_patientcard_identification_doc_image`}
                        style={img}
                        src={IdentificationDocuments}
                        alt="id"
                      />
                      <Text
                        id={`${parent_id}_${mrn}_patientcard_text`}
                        className="pc_regular"
                        style={span}
                      >
                        {props?.mrnVal ? props?.mrnVal : mrn}
                      </Text>
                    </Div>
                    <Div
                      id={`${parent_id}_patientcard_doc_sub_div`}
                      style={{ ...rowFlex, marginRight: 15 }}
                    >
                      <Image
                        id={`${parent_id}_patientcard_identification_doc_image`}
                        style={img}
                        src={groupIcon}
                        alt="id"
                      />
                      <Text
                        id={`${parent_id}_${mrn}_patientcard_text`}
                        className="pc_regular"
                        style={span}
                      >
                        {props?.newPatientDetails?.identifier?.[0]?.value}
                      </Text>
                    </Div>
                    <Div
                      id={`${parent_id}_patientcard_doc_sub_div`}
                      style={{ ...rowFlex, marginRight: 15 }}
                    >
                      <Image
                        id={`${parent_id}_patientcard_identification_doc_image`}
                        style={img}
                        src={phoneIcon}
                        alt="id"
                      />
                      <Text
                        id={`${parent_id}_${mrn}_patientcard_text`}
                        className="pc_regular"
                        style={span}
                      >
                        {props?.newPatientDetails?.telecom?.[0]?.value}
                      </Text>
                    </Div>
                  </Div>
                )}
                {!props.notOpen && (
                  <Div
                    id={`${parent_id}_patientcard_group_div`}
                    style={rowFlex}
                  >
                    <Image
                      id={`${parent_id}_patientcard_group_image`}
                      style={img}
                      src="images/Group 90503.svg"
                      alt="group"
                    />
                    <Text
                      id={`${parent_id}_${pid}_patientcard_text`}
                      className="pc_regular"
                      style={span}
                    >
                      {props?.pidVal ? props?.pidVal : pid}
                    </Text>
                  </Div>
                )}
              </Div>
            </Div>
          </Div>
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ display: "flex", flexDirection: "column",gap:"3px",justifyContent:"center",alignItems:"center",marginRight:"10px" }}>
                <Wallet
                  walletData={props?.walletData}
                  // walletClick={props?.walletClick}
                />
                <Outstanding
                  OutstandingData={props?.OutstandingData}
                  OutstandingClick={props?.OutstandingClick}
                />
          </div>
          {!props.noedit &&
          props.newPatientDetails?.name?.length > 0 &&
          permissionData?.write?.indexOf("patientBannerEditIcon") > -1 ? (
            <div style={editIcon} onClick={handleEditForm}>
              <EditOutlinedIcon
                style={{ cursor: "pointer", color: "#4071c3" }}
                id={`${parent_id}_image_edit_image`}
              />
            </div>
          ) : (
            <div>
              <Tooltip title="loading">
                <IconButton aria-label="loading">
                  <LoopIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </Card>
}
      {/* Divider */}
      {(isAddBtn || memberList.length > 0) && (
        <Divider
          id={`${parent_id}_patientcard_bottom_divider`}
          key={"1"}
          label=""
          borderColor=""
          textColor=""
          orientation="vertical"
          variant="middle"
          style={{
            height: "34px",
            borderColor: "#ffffff",
            backgroundColor: "#ffffff",
            marginRight: 0,
            marginLeft: 10,
          }}
          className={"pc_medium"}
        />
      )}
  
      {/* isAddBtn Div */}
      {(isAddBtn || memberList.length > 0) && (
        <Div
          id={`${parent_id}_patientcard_member_div`}
          inLineStyles={{ display: "flex", alignItems: "center" }}
        >
          {memberList.slice(0, 4).map((val) => {
            return (
              <React.Fragment key={val.src || val.label}>
                {val.src ? (
                  <Avatar
                    id={`${parent_id}_patientcard_member_avatar`}
                    variant="rounded"
                    style={{
                      height: "40px",
                      width: "40px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      color: "#6F6F6F",
                      margin: "0px 1px 0px 9px",
                      fontSize: "16px",
                    }}
                    src={val.src}
                    className={"pc_medium"}
                  />
                ) : (
                  <Avatar
                    id={`${parent_id}_patientcard_member_label_avatar`}
                    variant="rounded"
                    style={{
                      height: "40px",
                      width: "40px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      color: "#6F6F6F",
                      margin: "0px 1px 0px 9px",
                      fontSize: "16px",
                    }}
                    letter={val.label}
                    className={"pc_medium"}
                  />
                )}
              </React.Fragment>
            );
          })}
  
          {memberList.length - 4 > 0 && (
            <Div
              id={`${parent_id}_patientcard_memberlist_div`}
              variant="rounded"
              style={{
                height: "40px",
                width: "40px",
                cursor: "pointer",
                backgroundColor: "#fff",
                color: "#101010",
                margin: "0px 1px 0px 9px",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                borderRadius: 6,
              }}
              className={"pc_medium"}
            >
              +{memberList.length - 4} More
            </Div>
          )}
  
          {isAddBtn && (
            <Avatar
              id={`${parent_id}_patientcard_memberlist_avatar`}
              variant="rounded"
              style={{
                height: "50px",
                width: "40px",
                cursor: "pointer",
                backgroundColor: "#fff",
                margin: "0px 1px 0px 9px",
                color: "#6F6F6F",
                fontSize: "16px",
              }}
              letter="+"
              onClick={() => addMember(true)}
              className={"pc_medium"}
            />
          )}
        </Div>
      )}
    </div>
  </React.Fragment>
  
  );
}

export default PatientCard;
