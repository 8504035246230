import { SideMenu } from "qdm-component-library";
import React, { useEffect, useState } from "react";
import BookMultipleAppointmentsDrawer from "../../screens/appointmentBooking/components/appointmentDetails/doctorAndAppointmentSlot/bookmultipleAppointmentsDrawer/bookmultipleAppointmentsDrawer";
import BookMultipleAppointmentsLists from "../../screens/appointmentBooking/components/appointmentDetails/doctorAndAppointmentSlot/bookMultipleAppointmentsLists/bookMultipleAppointmentsLists";

function MultiBook(props) {
  const [state, setState] = useState({
    // showHide: 0,
    isMultipleAppointment: true,
    fromListing: {},
    info: props?.info,
    hospitalInfo: props?.hospitalInfo,
  });

  useEffect(() => {
    setState({ ...state, info: props?.info });
  }, [props?.info]);

  const { parent_id = "multibook" } = props;

  const changeState = (key, val) => {
    if (key === "bookAppoinment" && !val) {
      setState((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    }
  };

  return (
    <SideMenu
      id={`${parent_id}_appointmentslot_Bookappointment_sidemenu`}
      open={props.bookAppoinment}
      direction="right"
      width={650}
      color="default"
      onClose={() => {
        changeState("bookAppoinment", false);
        props?.setMultiBookOpen();
      }}
      style={{ zIndex: 999, padding: 0 }}
    >
      {props.showHide === 0 && (
        <BookMultipleAppointmentsDrawer
          parent_id={"doctor_listing"}
          multiApppointmentInfo={props.multiApppointmentInfo}
          handleMultiAppointmentChange={props.handleMultiAppointmentChange}
          changeState={changeState}
          previewSchedule={(info) => {
            if (info) {
              setState((prevState) => ({
                ...prevState,
                info,
                // showHide: 1,
              }));
              props.setShowHide(1);
            } else {
              props.setShowHide(1);
            }
          }}
          date={props.time}
          time={{ label: props.date }}
          hospitalInfo={props.hospitalInfo}
          shortcut={props?.shortcut}
          //slotInfo={props?.info}
          //info={state.info}
          //{...props}
          HandleWatingList={props?.HandleWatingList}
          maxWatingListCount={props?.maxWatingListCount}
          handleTabChange={props?.handleTabChange}
          availableWatingList={props?.availableWatingList}
          appointmentdurationIds={props?.appointmentdurationIds}
        />
      )}
      {props.showHide === 1 && (
        <BookMultipleAppointmentsLists
          loading={props?.loading}
          parent_id={"doctor_listing"}
          info={state.info}
          fromListing={props.fromListing}
          changeState={props.changeState}
          multiApppointmentInfo={props.multiApppointmentInfo}
          handleMultiAppointmentChange={props.handleMultiAppointmentChange}
          onChange={() => props.setShowHide(0)}
          confirmAppoinment={() => {
            props?.multiBooking();
          }}
          slotInfo={props?.info}
          shortcut={props?.shortcut}
          //{...props}
        />
      )}
    </SideMenu>
  );
}

export default MultiBook;
