import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  appoinmentRequest,
  urls,
  makeName,
  getImgUrl,
  getCurrentPosition,
  calculationExp,
  dbName,
  getLocationInfo,
  getUtcTime,
  query_ids,
  utcTOLocal,
  timeLine_,
  slotRequest,
} from "../../utils";
import jwtDecode from "jwt-decode";
import moment from "moment";

const {
  // reasonForAppointment,
  reasonForApptCancelModify,
  readPractData,
} = appoinmentRequest;

// REASON_FOR_APPOINTMENT
const REASON_FOR_APPOINTMENT = createAsyncThunk(
  `appointmentReducer/reasonForAppointment`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["reasonForAppointment"],
        filter: {
          type: ["REASON"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      const returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.REASON?.Value?.forEach((spec) => {
          returnData.push({
            title: spec?.display,
            value: spec?._id,
          });
        });
      }
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

// REASON_FOR_APPT_CANCEL_MODIFY
const REASON_FOR_APPT_CANCEL_MODIFY = createAsyncThunk(
  `appointmentReducer/reasonForApptCancelModify`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        { body: JSON.stringify(reasonForApptCancelModify()) },
        urls.readDocuments
      );
      const returnData = [];
      if (Array.isArray(data?.result)) {
        data?.result?.forEach((_) => {
          returnData.push({
            title: _?.display,
            value: _?.id,
            id: _?.id,
            code: _?.code,
            _id: _?._id,
          });
        });
      }
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

// APPOINTMENT_UPDATE
const APPOINTMENT_UPDATE_READ = createAsyncThunk(
  `appointmentReducer/upsertReadAppointment`,
  async (payload = {}, { rejectWithValue }) => {
    try {

      const { start = 0, id = [0], type = "", slotID } = payload;

      let Sdate = new Date(start * 1000);
      let Edate = new Date(start * 1000);
      let decodedToken = jwtDecode(localStorage.getItem("UserInfo"));

      let isfrontdesk = Boolean(decodedToken?.rolename);
      let startdate = Sdate;
      let enddate = Edate;
      startdate.setHours(0, 0, 0, 0);
      enddate.setHours(23, 59, 59, 999);
      let isClinic = false;
      if (type === "clinicid") {
        isClinic = true;
      }

      const practData = await fetchData(
        {
          body: JSON.stringify(readPractData(type, id)),
        },
        urls.readQdmQueries
      );

      const resp = await fetchData(
        {
          body: JSON.stringify(
            slotRequest.readSlot({
              startdate: getUtcTime(startdate),
              enddate: getUtcTime(enddate),
              practitionerid: practData?.[0]?._id ??id?.[0],
              facilityid: "",
              isfrontdesk,
            })
          ),
        },
        urls.readQdmQueries
      );
      const { lat: myLat, long: myLong } = await getCurrentPosition();
      const locationInfo = {};
      const city =
        (isClinic
          ? practData[0]?.managingOrgID?.[0]?.address?.[0]?.city?.geogLevelName //FIXME - check for org
          : practData[0]?.practitioner_role?.[0]?.OrgID?.[0]?.address?.[0]?.city
            ?.geogLevelName) || "";
      let kms = "0 kms";
      if (myLat && myLong && city) {
        await getLocationInfo({ lat: myLat, long: myLong }, city, locationInfo);
        kms = locationInfo[city] || "0 kms";
      }
      const data = resp?.[0] || {};
      const slots = data?.slot || [];
      let resourceInfo = {
        resourcecode: data?.resourcecode || "",
        resourcerole: data?.resourcerole || "",
        resourcetype: data?.resourcetype || "",
      };
      let healthCareId = data?.HealthCareServiceID || [];
      const info = {
        locationId: "",
        clinicId: 0,
        id: 0,
        subtext: "",
        name: "",
        title: "",
        price: "",
        isDoctor: !isClinic,
        language: [],
        isClinic,
        memberIncludes: "0",
        hospitalName: "",
        hospitalLocation: "",
        kms: "0 Kms",
        weekCalendar: new Date(start * 1000),
        isAllSession: isClinic,
        slots,
        healthCareId,
        morning: [],
        afternoone: [],
        evening: [],
        night: [],
        allSession: [],
        isUpdate: true,
        selectedSlot: slotID?.[0] || 0,
        specialities: [],
        orgId: 0,
        org_Id: "",
        memberIncludesImg: [],
        img: "",
        resourceInfo,
      };
      if (Array.isArray(practData)) {
        if (isClinic) {
          info["clinicId"] = practData[0]?.id;
          info["id"] = practData[0]?.managingOrgID?.[0]?.id;
          info["orgId"] = practData[0]?.managingOrgID?.[0]?.id;
          info["orgType"] = practData[0]?.managingOrgID?.[0]?.OrgType?.[0]?._id;
          info["org_Id"] = practData[0]?.managingOrgID?.[0]?._id;
          info["_id"] = practData[0]?._id;
          info["_key"] = practData[0]?._key;
          const s = practData[0]?.Specialties;
          if (s?.Type?.toLowerCase() === "specialty") {
            info.specialities.push({
              label: s?.display,
              id: s?._id,
            });
          }
          info["locationId"] = practData[0]?._id;
          info["name"] = practData[0]?.shortdesc || "";
          info["hospitalName"] = practData[0]?.managingOrgID?.[0]?.name || "";
          info["hospitalLocation"] = city || "";
          info["title"] =
            practData[0]?.managingOrgID?.[0]?.address?.[0]?.line || "";
          info["gender"] = null;
          info["memberIncludes"] = practData[0]?.member_count;
          info["memberIncludesImg"] = practData[0]?.Practitioner?.map((p) => {
            if (p) {
              return getImgUrl(p?.photo?.[0]?.fileid);
            }
            return "";
          });
          info["kms"] = kms;
          info["key"] = practData[0]?.managingOrgID?.[0]?._key;
          info["img"] = getImgUrl(practData[0]?.photo?.[0]?.fileid);
          // info["orgId"] = practData[0]?.Organization?.id;
          // info["org_Id"] = practData[0]?.Organization?._id;
          // info["clinicId"] = practData[0]?.id;
          // info["id"] = practData[0]?.id;
          // info["_id"] = practData[0]?._id;
          // info["_key"] = practData[0]?._key;
          // info["img"] = getImgUrl(practData[0]?.photo?.[0]?.fileid);
        } else {
          info["orgId"] = practData[0]?.practitioner_role?.[0]?.OrgID?.id;
          const languages = new Set();
          practData[0]?.communication?.forEach((c) => {
            if (c?.Type?.toLowerCase() === "language") {
              const cd = c?.coding?.[0];
              if (cd?.Type?.toLowerCase() === "language") {
                languages.add(cd?.display);
              }
            }
          });
          info["id"] = practData[0]?.["id"];
          const gender = practData[0]?.gender?.[0]?.coding?.[0]?.display || "";
          let designation = "";
          [practData?.[0]?.practitioner_role?.[0]?.SpecialtyID || {}]?.forEach(
            (s) => {
              s?.coding?.forEach((val) => {
                if (val?.display) {
                  if (designation) {
                    designation += ", ";
                  }
                  designation += val?.display;
                  info.specialities.push({
                    label: val?.display,
                    id: s?.id,
                  });
                }
              });
            }
          );
          info["role"] =
            practData[0]?.practitioner_role?.[0]?.role?.[0]?.coding?.[0]?.display;
          info["mobile"] =
            practData[0]?.practitioner_role?.[0]?.OrgID?.contact?.[0]?.telecom?.[0]?.value;
          info["title"] = `${gender}${gender && " | "}${designation}`;
          info["gender"] = gender;
          info["subtext"] =
            practData[0]?.qualification
              ?.map((q) => q?.name || "")
              ?.join(", ") || "MBBS";
          info["language"] = languages.size > 0 ? [...languages] : [];
          info["hospitalName"] =
            practData[0]?.practitioner_role?.[0]?.OrgID?.name || "";
          info["hospitalLocation"] = practData[0]?.address?.[0]?.line || "";
          const nameObj = practData[0]?.name?.[0] || {};
          info["name"] = makeName(nameObj);
          info["kms"] = kms;
          info["orgType"] =
            practData[0]?.practitioner_role?.[0]?.OrgID?.OrgType?.[0];
          info["org_Id"] =
            practData[0]?.practitioner_role?.[0]?.OrgID?._id ??
            practData[0]?.practitioner_role?.[0]?.EntityName;
          info["_id"] = practData[0]?._id;
          info["_key"] = practData[0]?._key;
          info["img"] = getImgUrl(practData[0]?.photo?.[0]?.fileid);
          info["bio"] =
            practData[0]?.biography?.[0]?.biography_description || "";
          info["experience"] = calculationExp(practData[0]?.job_experience);
          info["hospitalLocation"] =
            practData[0]?.practitioner_role?.[0]?.OrgID?.address?.[0]?.city
              ?.geogLevelName || "";
        }
      }
      return {
        ...defaultState.Info,
        data: [info],
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const CHECK_APPOINTMENT_AGAINST_PATIENT = createAsyncThunk(
  `appointmentReducer/checkAppointmentAgainstPatient`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {
        personid = "",
        start = 0,
        tenantid = "",
        facilityid = "",
      } = payload;

      const body = {
        db_name: dbName,
        queryid: query_ids["appointmentReducer/checkAppointmentAgainstPatient"],
        filter: {
          personid: personid,
          start: start,
          tenantid: tenantid,
          facilityid: facilityid,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const ALL_APPOINTMENTS = createAsyncThunk(
  `appointmentReducer/allAppointments`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {
        patientid = "",
        tenantid = "",
        facilityid = "",
        startdate=0,
        assemble = false,
      } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["appointmentReducer/allAppointments"],
        filter: {
          patientid: patientid,
          tenantid: tenantid,
          facilityid: facilityid,
          startdate:startdate
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      const returnData = {
        pastAppointment: [],
        pastAppointmentThisWeek: [],
        pastAppointmentLastWeek: [],
        pastAppointmentThisMonth: [],
        pastAppointmentLastMonth: [],

        upcomingAppointment: [],
        upcomingAppointmentToday: [],
        upcomingAppointmentThisWeek: [],
        upcomingAppointmentNextWeek: [],
        upcomingAppointmentThisMonth: [],
        upcomingAppointmentNextMonth: [],

        followUpAppointment: [],
        currentVisist: [],
      };
      //console.clear();
      data[0]?.past_appointment?.forEach?.((v) => {
        console.log(utcTOLocal(v.start));
        let type_ = timeLine_(v.start);
        // returnData.currentVisist.push(v);
        if (type_ === "TODAY" && v.appstatus === "cancelled") {
          returnData.pastAppointmentThisWeek.push(v);
          returnData.pastAppointment.push(v);
        } else if (type_ === "THIS_WEEK" && v.appstatus === "cancelled") {
          returnData.pastAppointmentThisWeek.push(v);
          returnData.pastAppointment.push(v);
        } else if (type_ === "LAST_WEEK" && v.appstatus === "cancelled") {
          returnData.pastAppointmentLastWeek.push(v);
          returnData.pastAppointment.push(v);
        } else if (type_ === "THIS_MONTH" && v.appstatus === "cancelled") {
          returnData.pastAppointmentThisMonth.push(v);
          returnData.pastAppointment.push(v);
        } else if (type_ === "LAST_MONTH" && v.appstatus === "cancelled") {
          returnData.pastAppointmentLastMonth.push(v);
          returnData.pastAppointment.push(v);
        } else if (type_ === "TODAY") {
          returnData.currentVisist.push(v);
        } else if (type_ === "NEXT_WEEK") {
          returnData.currentVisist.push(v);
          // returnData.pastAppointment.push(v);
        } else if (type_ === "THIS_WEEK") {
          returnData.pastAppointmentThisWeek.push(v);
          returnData.pastAppointment.push(v);
        } else if (type_ === "LAST_WEEK") {
          returnData.pastAppointmentLastWeek.push(v);
          returnData.pastAppointment.push(v);
        } else if (type_ === "THIS_MONTH") {
          returnData.pastAppointmentThisMonth.push(v);
          returnData.pastAppointment.push(v);
        } else if (type_ === "LAST_MONTH") {
          returnData.pastAppointmentLastMonth.push(v);
          returnData.pastAppointment.push(v);
        } else {
          returnData.pastAppointment.push(v);
        }
      });
      data[0]?.upcoming_appointment?.forEach?.((v) => {
        let type_ = timeLine_(v.start);

        if (type_ === "TODAY") {
          returnData.upcomingAppointmentToday.push(v);
          returnData.upcomingAppointment.push(v);
        } else if (type_ === "THIS_WEEK") {
          returnData.upcomingAppointmentThisWeek.push(v);
          returnData.upcomingAppointment.push(v);
        } else if (type_ === "NEXT_WEEK") {
          returnData.upcomingAppointmentNextWeek.push(v);
          returnData.upcomingAppointment.push(v);
        } else if (type_ === "THIS_MONTH") {
          returnData.upcomingAppointmentThisMonth.push(v);
          returnData.upcomingAppointment.push(v);
        } else if (type_ === "NEXT_MONTH") {
          returnData.upcomingAppointmentNextMonth.push(v);
          returnData.upcomingAppointment.push(v);
        } else {
          returnData.upcomingAppointment.push(v);
        }
      });
      data[0]?.currentvisit?.forEach?.((v) => {
        let type_ = timeLine_(v.start);

        if (type_ === "TODAY") {
          returnData.currentVisist.push(v);
        } else {
          returnData.currentVisist.push(v);
        }
      });
      data[0]?.followup?.forEach?.((v) => {
        returnData.followUpAppointment.push(v);
      });
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET__FOLLOW_UP_APPOINTMENT = createAsyncThunk(
  `appointmentReducer/follow_up_Appointment`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientId } = payload;

      const body = {
        db_name: dbName,
        entity: "AppointmentRequest",
        filter: `AppointmentRequest.status =='pending' && AppointmentRequest.patientid=='${patientId}' and DATE_TIMESTAMP(DATE_ADD(AppointmentRequest.encounterdate*1000,AppointmentRequest.followupdurval,DOCUMENT(AppointmentRequest.followupduruom).display=='Days'?'day':DOCUMENT(AppointmentRequest.followupduruom).display=='month'?'month':'week'))>=DATE_TIMESTAMP(DATE_NOW())`,
        return_fields:
          "merge(AppointmentRequest,{encounterid:(for e in Encounter filter e._id == AppointmentRequest.encounterid return merge(e,{appointmentId:(for app in Appointment filter app.id in e.appointmentId return app.consultationMode)}))},{followupduruom:document(AppointmentRequest.followupduruom)},{followupreason:document(AppointmentRequest.followupreason)},{healthserviceid:to_array(document(AppointmentRequest.healthserviceid))},{orgid:to_array(document(AppointmentRequest.orgid))}, {practitionerid:(for doc in Practitioner filter doc._id == AppointmentRequest.practitionerid return merge(doc,{name:(for name in HumanNameMaster filter name._id in doc.name return merge(name,{use:document(name.use)},{prefix:merge(document(name.prefix),{coding:document(document(name.prefix).coding)})},{suffix:merge(document(name.suffix),{coding:document(document(name.suffix).coding)})}))}, {practitioner_role:(for pr in PractitionerRole filter pr._id in doc.practitioner_role return merge(pr,{role:document(pr.role)}))},{photo:document(doc.photo)}))}, {patientid:(for pat in Patient filter pat._id == AppointmentRequest.patientid return merge (pat,{name:(for name in HumanNameMaster filter name._id in pat.name return merge(name,{use:(document(name.use))},{prefix:(document(name.prefix))},{suffix:document(name.suffix)}))}))},{specialtyid:document(AppointmentRequest.specialtyid)})",
      };

      // const body = {
      //   "db_name": dbName,
      //   "entity": "AppointmentRequest",
      //   "filter": `AppointmentRequest.patientid=='${patientId}' AND AppointmentRequest.appointmentid==''and DATE_TIMESTAMP(DATE_ADD(AppointmentRequest.encounterdate*1000,AppointmentRequest.followupdurval,DOCUMENT(AppointmentRequest.followupduruom).display=='Days'?'day':DOCUMENT(AppointmentRequest.followupduruom).display=='month'?'month':'week'))>=DATE_TIMESTAMP(DATE_NOW())`,
      //   "return_fields": "merge(AppointmentRequest,{encounterid:(for e in Encounter filter e._id == AppointmentRequest.encounterid return merge(e,{appointmentId:(for app in Appointment filter app.id in e.appointmentId return app.consultationMode)}))},{followupduruom:document(AppointmentRequest.followupduruom)},{followupreason:document(AppointmentRequest.followupreason)},{healthserviceid:to_array(document(AppointmentRequest.healthserviceid))},{orgid:to_array(document(AppointmentRequest.orgid))},{practitionerid:(for doc in Practitioner filter doc._id == AppointmentRequest.practitionerid return merge(doc,{name:(for name in HumanNameMaster filter name._id in doc.name return merge(name,{use:(document(name.use))},{prefix:(document(name.prefix))},{suffix:document(name.suffix)}))},{practitioner_role:(for pr in PractitionerRole filter pr._id in doc.practitioner_role return merge(pr,{role:document(pr.role)}))}))},{patientid:(for pat in Patient filter pat._id == AppointmentRequest.patientid return merge (pat,{name:(for name in HumanNameMaster filter name._id in pat.name return merge(name,{use:(document(name.use))},{prefix:(document(name.prefix))},{suffix:document(name.suffix)}))}))},{specialtyid:document(AppointmentRequest.specialtyid)})"
      // };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readDocuments
      );

      let followUpAppointmentData = [];

      data?.result?.map((v) => {
        return followUpAppointmentData.push({
          consultationMode: v?.encounterid?.[0]?.appointmentId ?? "",
          Practioner: [
            {
              name: [
                {
                  prefix:
                    v?.practitionerid?.[0]?.name?.[0]?.prefix?.display ?? "",
                  given: v?.practitionerid?.[0]?.name?.[0]?.given ?? "",
                  family: v?.practitionerid?.[0]?.name?.[0]?.family ?? "",
                  suffix:
                    v?.practitionerid?.[0]?.name?.[0]?.suffix?.display ?? "",
                },
              ],
              roles: [
                {
                  healthcareservices: [
                    {
                      specialty: [
                        {
                          Type: "speciality",
                          text:
                            v?.practitionerid?.[0]?.practitioner_role?.[0]?.role
                              ?.display ?? "",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          ...v,
        });
      });
      return {
        ...defaultState.List,
        data: followUpAppointmentData,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        data: {
          error: error.message || error,
        },
      });
    }
  }
);

// APPOINTMENT_READ
const APPOINTMENT_READ = createAsyncThunk(
  `appointmentReducer/readAppointment`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { appointmentid = "", tenantid = "", facilityid = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["appointmentReducer/readAppointment"],
        filter: {
          appointmentid: appointmentid,
          tenantid: tenantid,
          facilityid: facilityid,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      // const returnData = [data];
      return {
        ...defaultState.List,
        data: data?.[0]?.[0] || {},
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_TICKETID_BY_APPID = createAsyncThunk(
  `appointmentReducer/getTicketIdByAppId`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { appointmentReqId = ""} = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["appointmentReducer/getTicketIdByAppointmentId"],
       filter: {
        apprequestid: appointmentReqId,
                },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      // const returnData = [data];
      return {
        ...defaultState.List,
        data: data
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const WAITINGLIST_UPDATE_STATUS = createAsyncThunk(
  `appointmentReducer/waitingListUpdateStatus`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { appointmentReqId = ""} = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["appointmentReducer/waitingListUpdateStatus"],
       filter: {
        requestid: appointmentReqId,
        status: "CodingMaster/12927"        
      },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      // const returnData = [data];
      return {
        ...defaultState.List,
        data: data
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const WatingList_APPOINTMENT_READ = createAsyncThunk(
  `appointmentReducer/waitingListAppointmentRead`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { appointmentrequestid = "", tenantid = "", facilityid = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["appointmentReducer/waitingListAppointmentRead"],
        filter: {
          appointmentrequestid: appointmentrequestid
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      // const returnData = [data];
      return {
        ...defaultState.List,
        data: data
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const RECCURANCE_APPOINTMENT = createAsyncThunk(
  `appointmentReducer/reoccuranceAppointment`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      // const { date = [], resourceid = "", slotdate = 0 } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.multiBookApptmntRecurrence
      );
      return {
        ...defaultState.List,
        data: data?.response?.scheduleDetails || {},
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const READ_CLINICINFO = createAsyncThunk(
  `appointmentReducer/readClinicInfo`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { locationid = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["appointmentReducer/readClinicInfo"],
        filter: {
          locationid: locationid,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data || {},
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GENERAL_INSTRUCTION = createAsyncThunk(
  `appointmentReducer/generalInstruction`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { orgid = "", apptypecode = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["appointmentReducer/generalInstruction"],
        filter: {
          orgid: orgid,
          apptypecode: apptypecode,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data || {},
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const APPOINTMENT_CREATE = createAsyncThunk(
  `appointmentReducer/upsertAppointment`,
  async (payload = [], { rejectWithValue }) => {
    try {
      // const { AppointmentId = "" ,doc = [],IsModifyUser ="", ...rest } = payload;
      // const body = [];
      // doc.forEach((v) => {
      //   body.push({
      //     db_name: dbName,
      //     entity: "Appointment",
      //     is_metadata: true,
      //     metadataId: process.env.REACT_APP_METADATAID,
      //     ...(rest?.filter && rest?.filter),
      //     metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
      //     filter: {
      //       _id: AppointmentId,
      //     },
      //     doc: {
      //       ...v,
      //     },
      //   });
      // });
      const { AppointmentId = "", doc = [], IsModifyUser = "", ...rest } = payload;
      const body = [];
      
      doc.forEach((v) => {
        const filter = IsModifyUser && AppointmentId ? { _id: AppointmentId } : {};
      
        body.push({
          db_name: dbName,
          entity: "Appointment",
          is_metadata: true,
          metadataId: process.env.REACT_APP_METADATAID,
          ...(rest?.filter && rest?.filter),
          metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
          filter,
          doc: {
            ...v,
          },
        });
      });

      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.bookAppointment
      );
      console.log("Book Appointment", data);

      return {
        ...defaultState.Info,
        data: data ?? [],
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const CANCEL_APPOINTMENT = createAsyncThunk(
  `appointmentReducer/cancelAppointment`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { ticketId = "", cancelationReason = [], cancellationType = "",appId="",cancelationReasondesc="" } = payload;
      const body = {
        ticketId: ticketId,
        userAction: "CANCEL",
        data: {
          ticketId: ticketId,
          cancelationReason: cancelationReason,
          cancellationType,
          appId,
          cancelationReasondesc
        },
      };
      
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        process.env.REACT_APP_PERFORMUSERACTION_API
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const SCHEDULE_APPOINTMENT = createAsyncThunk(
  `appointmentReducer/scheduleAppointment`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {
        ticketId = "",
        practitionerId = "",
        practitionerRoleId = "",
      } = payload;
      const body = {
        ticketId: ticketId,
        userAction: process.env.REACT_APP_REFERRAL_SCHEDULE,
        data: {
          ticketId: ticketId,
          practitionerId: practitionerId,
          practitionerRoleId: practitionerRoleId,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        process.env.REACT_APP_PERFORMUSERACTION_API
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const WATING_LIST_APPOINMENTS = createAsyncThunk(
  `appointmentReducer/watingListAppoinments`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { startDate = "", patientId = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["appointmentReducer/watingListAppoinments"],
        filter: {
          start: startDate,
	        patientid: patientId
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data || {},
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const appointmentReducer = createSlice({
  name: "appointmentReducer",
  initialState: {
    reasonForAppointMent: {
      ...defaultState.List,
    },
    reasonForApptCancelModify: {
      ...defaultState.Info,
    },
    upsertReadAppointment: {
      ...defaultState.List,
    },
    checkAppointmentAgainstPatient: {
      ...defaultState.Info,
    },
    allAppointments: {
      ...defaultState.Info,
    },
    follow_up_appintment: {
      ...defaultState.Info,
    },
    readAppointment: {
      ...defaultState.Info,
    },
    reoccuranceAppointment: {
      ...defaultState.Info,
    },
    readClinicInfo: {
      ...defaultState.Info,
    },
    generalInstruction: {
      ...defaultState.Info,
    },
    upsertAppointment: {
      ...defaultState.Info,
    },
    cancelAppointment: {
      ...defaultState.Info,
    },
    scheduleAppointment: { ...defaultState.Info },
    watingListAppoinments: { ...defaultState.Info },
    waitingListAppointmentRead: { ...defaultState.Info },
    getTicketIdByAppId: { ...defaultState.Info },
    waitingListUpdateStatus: { ...defaultState.Info },
  },
  extraReducers: {
    /* REASON_FOR_APPOINTMENT */
    [REASON_FOR_APPOINTMENT.fulfilled]: (state, action) => {
      state.reasonForAppointMent = action?.payload ?? {};
    },
    [REASON_FOR_APPOINTMENT.pending]: (state, action) => {
      state.reasonForAppointMent.loading = true;
    },
    [REASON_FOR_APPOINTMENT.rejected]: (state, action) => {
      state.reasonForAppointMent = action?.payload ?? {};
    },

    /* REASON_FOR_APPT_CANCEL_MODIFY */
    [REASON_FOR_APPT_CANCEL_MODIFY.fulfilled]: (state, action) => {
      state.reasonForApptCancelModify = action?.payload ?? [];
    },
    [REASON_FOR_APPT_CANCEL_MODIFY.pending]: (state, action) => {
      state.reasonForApptCancelModify.loading = true;
    },
    [REASON_FOR_APPT_CANCEL_MODIFY.rejected]: (state, action) => {
      state.reasonForApptCancelModify = action?.payload ?? [];
    },
    /* APPOINTMENT_UPDATE_READ */
    [APPOINTMENT_UPDATE_READ.fulfilled]: (state, action) => {
      state.upsertReadAppointment = action?.payload ?? {};
    },
    [APPOINTMENT_UPDATE_READ.pending]: (state, action) => {
      state.upsertReadAppointment.loading = true;
    },
    [APPOINTMENT_UPDATE_READ.rejected]: (state, action) => {
      state.upsertReadAppointment = action?.payload ?? {};
    },

    //CHECK_APPOINTMENT_AGAINST_PATIENT
    [CHECK_APPOINTMENT_AGAINST_PATIENT.fulfilled]: (state, action) => {
      state.checkAppointmentAgainstPatient = action?.payload ?? [];
    },
    [CHECK_APPOINTMENT_AGAINST_PATIENT.pending]: (state, action) => {
      state.checkAppointmentAgainstPatient.loading = true;
    },
    [CHECK_APPOINTMENT_AGAINST_PATIENT.rejected]: (state, action) => {
      state.checkAppointmentAgainstPatient = action?.payload ?? [];
    },
    [ALL_APPOINTMENTS.fulfilled]: (state, action) => {
      state.allAppointments = action?.payload ?? [];
    },
    [ALL_APPOINTMENTS.pending]: (state, action) => {
      state.allAppointments.loading = true;
    },
    [ALL_APPOINTMENTS.rejected]: (state, action) => {
      state.allAppointments = action?.payload ?? [];
    },
    /* GET__FOLLOW_UP_APPOINTMENT */
    [GET__FOLLOW_UP_APPOINTMENT.fulfilled]: (state, action) => {
      state.follow_up_appintment = action?.payload ?? [];
    },
    [GET__FOLLOW_UP_APPOINTMENT.pending]: (state, action) => {
      state.follow_up_appintment.loading = true;
    },
    [GET__FOLLOW_UP_APPOINTMENT.rejected]: (state, action) => {
      state.follow_up_appintment = action?.payload ?? [];
    },

    /* APPOINTMENT_READ */
    [APPOINTMENT_READ.fulfilled]: (state, action) => {
      state.readAppointment = action?.payload ?? {};
    },
    [APPOINTMENT_READ.pending]: (state, action) => {
      state.readAppointment.loading = true;
    },
    [APPOINTMENT_READ.rejected]: (state, action) => {
      state.readAppointment = action?.payload ?? {};
    },

    /* RECCURANCE_APPOINTMENT */
    [RECCURANCE_APPOINTMENT.fulfilled]: (state, action) => {
      state.reoccuranceAppointment = action?.payload ?? {};
    },
    [RECCURANCE_APPOINTMENT.pending]: (state, action) => {
      state.reoccuranceAppointment.loading = true;
    },
    [RECCURANCE_APPOINTMENT.rejected]: (state, action) => {
      state.reoccuranceAppointment = action?.payload ?? {};
    },

    /* READ_CLINICINFO */
    [READ_CLINICINFO.fulfilled]: (state, action) => {
      state.readClinicInfo = action?.payload ?? {};
    },
    [READ_CLINICINFO.pending]: (state, action) => {
      state.readClinicInfo.loading = true;
    },
    [READ_CLINICINFO.rejected]: (state, action) => {
      state.readClinicInfo = action?.payload ?? {};
    },

    /* GENERAL_INSTRUCTION */
    [GENERAL_INSTRUCTION.fulfilled]: (state, action) => {
      state.generalInstruction = action?.payload ?? {};
    },
    [GENERAL_INSTRUCTION.pending]: (state, action) => {
      state.generalInstruction.loading = true;
    },
    [GENERAL_INSTRUCTION.rejected]: (state, action) => {
      state.generalInstruction = action?.payload ?? {};
    },

    /* APPOINTMENT_CREATE */
    [APPOINTMENT_CREATE.fulfilled]: (state, action) => {
      state.upsertAppointment = action?.payload ?? {};
    },
    [APPOINTMENT_CREATE.pending]: (state, action) => {
      state.upsertAppointment.loading = true;
    },
    [APPOINTMENT_CREATE.rejected]: (state, action) => {
      state.upsertAppointment = action?.payload ?? {};
    },
    /* CANCEL_APPOINTMENT */
    [CANCEL_APPOINTMENT.fulfilled]: (state, action) => {
      state.cancelAppointment = action?.payload ?? {};
    },
    [CANCEL_APPOINTMENT.pending]: (state, action) => {
      state.cancelAppointment.loading = true;
    },
    [CANCEL_APPOINTMENT.rejected]: (state, action) => {
      state.cancelAppointment = action?.payload ?? {};
    },

    /*SCHEDULE_APPOINTMENT*/
    [SCHEDULE_APPOINTMENT.fulfilled]: (state, action) => {
      state.cancelAppointment = action?.payload ?? {};
    },
    [SCHEDULE_APPOINTMENT.pending]: (state, action) => {
      state.cancelAppointment.loading = true;
    },
    [SCHEDULE_APPOINTMENT.rejected]: (state, action) => {
      state.cancelAppointment = action?.payload ?? {};
    },
     /*WATING_LIST_APPOINMENTS*/
     [WATING_LIST_APPOINMENTS.fulfilled]: (state, action) => {
      state.watingListAppoinments = action?.payload ?? {};
    },
    [WATING_LIST_APPOINMENTS.pending]: (state, action) => {
      state.watingListAppoinments.loading = true;
    },
    [WATING_LIST_APPOINMENTS.rejected]: (state, action) => {
      state.watingListAppoinments = action?.payload ?? {};
    },
     /*WatingList_APPOINTMENT_READ*/
     [WatingList_APPOINTMENT_READ.fulfilled]: (state, action) => {
      state.waitingListAppointmentRead = action?.payload ?? {};
    },
    [WatingList_APPOINTMENT_READ.pending]: (state, action) => {
      state.waitingListAppointmentRead.loading = true;
    },
    [WatingList_APPOINTMENT_READ.rejected]: (state, action) => {
      state.waitingListAppointmentRead = action?.payload ?? {};
    },
    /*GET_TICKETID_BY_APPID*/
    [GET_TICKETID_BY_APPID.fulfilled]: (state, action) => {
      state.getTicketIdByAppId = action?.payload ?? {};
    },
    [GET_TICKETID_BY_APPID.pending]: (state, action) => {
      state.getTicketIdByAppId.loading = true;
    },
    [GET_TICKETID_BY_APPID.rejected]: (state, action) => {
      state.getTicketIdByAppId = action?.payload ?? {};
    },
     /*WAITINGLIST_UPDATE_STATUS*/
     [WAITINGLIST_UPDATE_STATUS.fulfilled]: (state, action) => {
      state.waitingListUpdateStatus = action?.payload ?? {};
    },
    [WAITINGLIST_UPDATE_STATUS.pending]: (state, action) => {
      state.waitingListUpdateStatus.loading = true;
    },
    [WAITINGLIST_UPDATE_STATUS.rejected]: (state, action) => {
      state.waitingListUpdateStatus = action?.payload ?? {};
    },
  },
});
// end

// APPOINTMENT ACTION LISTS
export const appointmentActions = {
  REASON_FOR_APPOINTMENT,
  REASON_FOR_APPT_CANCEL_MODIFY,
  APPOINTMENT_UPDATE_READ,
  CHECK_APPOINTMENT_AGAINST_PATIENT,
  ALL_APPOINTMENTS,
  GET__FOLLOW_UP_APPOINTMENT,
  APPOINTMENT_READ,
  RECCURANCE_APPOINTMENT,
  READ_CLINICINFO,
  GENERAL_INSTRUCTION,
  APPOINTMENT_CREATE,
  CANCEL_APPOINTMENT,
  SCHEDULE_APPOINTMENT,
  WATING_LIST_APPOINMENTS,
  WatingList_APPOINTMENT_READ,
  GET_TICKETID_BY_APPID,
  WAITINGLIST_UPDATE_STATUS
};

export default appointmentReducer;
