import {
  Div,
  Col,
  Divider,
  Row,
  Text,
  WeekCalendar,
  Tabpanel,
  Button,
  Icons,
  Tab,
} from "qdm-component-library";
import {
  convertTime,
  getUtcTime,
  getTimeSlot,
  utcTOLocal,
  getTimeString,
  checkError,
  waitListAppointmentId,
  AlertProps,
} from "../../../utils";
import moment from "moment";
import { useStyles } from "./styles";
import Popup from "@material-ui/core/Popover";
import CustomDatePicker from "../../../components/customDatePicker";
import { Calendar } from "../../../components/svg";
import { useCallback, useEffect, useState } from "react";
import TapComp from "./tapComp";
import actions from "../../../redux/actions/index";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import {
  AlertContext,
  PatientDetailsContext,
  SetPatientDetailsContext,
} from "../../../context";
import jwtDecode from "jwt-decode";
import { AppRoutes } from "../../../router/routes";
import { useSelector } from "react-redux";

const icons = {
  video: (
    <Icons
      key={"0"}
      fontIcon="video-camera"
      ariaHidden="true"
      size="small"
      style={{ color: "#B6B6B6" }}
    ></Icons>
  ),
  direct: (
    <Icons
      key={"0"}
      fontIcon="user"
      ariaHidden="true"
      size="small"
      style={{ color: "#B6B6B6" }}
    ></Icons>
  ),
  suggested: (
    <Icons
      key={"0"}
      fontIcon="star"
      ariaHidden="true"
      size="small"
      style={{ color: "#0071F2" }}
    ></Icons>
  ),
};

const SlotCalandar = (parentprops) => {
  const props = parentprops?.props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const alert = useContext(AlertContext);
  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo = userInfo && jwtDecode(userInfo);
  const { parent_id } = props;
  let setPatientDatacontext = useContext(SetPatientDetailsContext);
  let patientDetails = useContext(PatientDetailsContext);
  const [maxWatingListCount, setMaxWatingListCount] = useState([]);
  const [selectedSlotEndTiming, setSelectedSlotEndTiming] = useState("");
  const [appointmentdurationId, setAppointmentdurationId] = useState("");
  const [selectedTabTitle, setSelectedTabTitle] = useState('DAY001'); 
  const [isWatingListButtonClick,setIsWatingListButtonClick] = useState(false)

  // Utility to handle time formatting (this should be defined somewhere else in your code)
  const getTimeSlot = useCallback((time, format = "DD-MM-YYYY") => {
    return moment(time).format(format);
  }, []);
  const filterSlots = (
    resourceInfo, // not using these resourceinfo its coming from schedule
    slots,
    morning,
    afternoone,
    evening,
    night,
    allSession,
    isClinic,
    healthCareId,
    isUpdate = false,
    selectedSlot = 0
  ) => {
    const obj = {
      selectedTimeSlot: {},
      selectedTime: getTimeSlot(
        moment(props.data?.weekCalendar).format("DD-MM-YYYY")
      ),
    };

    let oldSlotData;
    console.log("slots", slots);
    slots &&
      slots.forEach((slot) => {
        if (slot) {
          let {
            start,
            _id: slotId,
            status,
            end,
            isdirect = false,
            issuggest = false,
            isvideo = false,
            Maxbooking = 0,
            Maxwaiting = 0,
            bookedCount = 0,
            maxgeneral = 0,
            maxwalkin = 0,
            appointmentType,
            consulttype,
            resourcecode,
            resourcerole,
            resourcetype,

            _id,
          } = slot;
          //console.log("consulttype" , consulttype?.display)
          console.log("preferedslottype", slot?.preferedslottype);
          const preferedslottype = slot?.preferedslottype
            .split(",")
            .map((a) => a.toLowerCase());

          if (status !== "closed" && status !== "booked") {
            //const slotTime = new Date(start * 1000);
            const slotTime = utcTOLocal(start).toDate();
            const hours = slotTime.getHours();
            let label = getTimeString(hours, slotTime.getMinutes());
            if (isClinic) {
              const endTime = new Date(end * 1000);
              const endHours = endTime.getHours();
              const endTimeLabel = getTimeString(
                endHours,
                endTime.getMinutes()
              );
              label = `${label} - ${endTimeLabel}`;
            }
            if (
              preferedslottype.includes("direct") ||
              preferedslottype.includes("both")
            ) {
              isdirect = true;
            }
            if (
              preferedslottype.includes("video") ||
              preferedslottype.includes("both")
            ) {
              isvideo = true;
            }
            const iconsArr = [];
            // const icon = <VideoConsultation />;
            if (isdirect) {
              iconsArr.push(icons["direct"]);
            }
            if (isvideo) {
              iconsArr.push(icons["video"]);
            }
            if (issuggest) {
              iconsArr.push(icons["suggested"]);
            }
            const slotData = {
              value: slot?._id,
              label,
              _id,
              date: slotTime.getTime(),
              dateEnd: end,
              dateStart: start,
              status,
              isdirect,
              issuggest,
              isvideo,
              booked: bookedCount,
              maxBooking: Maxbooking,
              maxWaiting: Maxwaiting,
              waiting: bookedCount > Maxbooking ? bookedCount - Maxbooking : 0,
              icon: iconsArr,
              healthCareId,
              maxgeneral,
              isWalkin:
                moment()
                  .startOf("day")
                  .diff(moment(start * 1000), "d") === 0
                  ? true
                  : false,
              maxwalkin,
              resourcecode,
              resourcerole,
              resourcetype,
              appointmentType,
            };
            if (isClinic) {
              allSession.push(slotData);
            } else {
              if (slot?.DayType?.display?.toLowerCase() === "morning") {
                morning.push(slotData);
              } else if (
                slot?.DayType?.display?.toLowerCase() === "afternoon"
              ) {
                afternoone.push(slotData);
              } else if (slot?.DayType?.display?.toLowerCase() === "evening") {
                evening.push(slotData);
              } else if (slot?.DayType?.display?.toLowerCase() === "night") {
                night.push(slotData);
              } else {
                morning.push(slotData);
              }
            }
            if (isUpdate) {
              if (slotId === selectedSlot) {
                oldSlotData = {
                  isUpdate: true,
                  info: slotData,
                  hospitalInfo: props.data,
                  appInfoFetched: {},
                };
                obj.selectedTime = getTimeSlot(start, "unix", true);
              }
            }
          }
        }
      });
    if (isUpdate) {
      if (!oldSlotData) {
        const {
          start,
          _id: slotId,
          status,
          end,
          isdirect = false,
          issuggest = false,
          isvideo = false,
          Maxbooking = 0,
          Maxwaiting = 0,
          bookedCount = 0,
          maxgeneral = 0,
          maxwalkin = 0,
          _id,
        } = props.oldAppointment?.Slots?.[0] || {};
        const slotTime = start ? new Date(start * 1000) : new Date();
        const hours = slotTime.getHours();
        let label = getTimeString(hours, slotTime.getMinutes());
        const slotInfo = {
          value: slotId,
          label,
          _id,
          date: slotTime.getTime(),
          dateEnd: end,
          dateStart: start,
          status,
          isdirect,
          issuggest,
          isvideo,
          booked: bookedCount,
          maxBooking: Maxbooking,
          maxWaiting: Maxwaiting,
          waiting:
            bookedCount > Maxbooking ? bookedCount - Maxbooking : Maxwaiting,
          icon: [],
          healthCareId,
          maxgeneral,
          isWalkin:
            moment()
              .startOf("day")
              .diff(moment(start * 1000), "d") === 0
              ? true
              : false,
          maxwalkin,
        };
        oldSlotData = {
          isUpdate: true,
          info: slotInfo,
          hospitalInfo: props.data,
          appInfoFetched: {},
        };
      }
      props.handleChange("oldSlot", oldSlotData);
    }
    return obj;
  };

  useEffect(() => {
    const tempdata = JSON.parse(JSON.stringify(props.data));
    const obj = filterSlots(
      tempdata.resourceInfo,
      tempdata.slots,
      tempdata.morning,
      tempdata.afternoone,
      tempdata.evening,
      tempdata.night,
      tempdata.allSession,
      tempdata.isClinic,
      tempdata.healthCareId,
      tempdata.isUpdate,
      tempdata.selectedSlot
    );
    setState((prevState) => {
      const isDataChanged = JSON.stringify(prevState.data) !== JSON.stringify(tempdata);
      if (isDataChanged) {
        return {
          ...prevState,
          data: { ...tempdata },
          ...obj,
        };
      } else {
        return prevState;
      }
    });
    
  }, [props?.data]);
  
  
  // const filterSlots = (
  //   resourceInfo, // not using these resourceinfo its coming from schedule
  //   slots,
  //   morning,
  //   afternoone,
  //   evening,
  //   night,
  //   allSession,
  //   isClinic,
  //   healthCareId,
  //   isUpdate = false,
  //   selectedSlot = 0
  // ) => {
  //   debugger
  //   const obj = {
  //     selectedTimeSlot: {},
  //     selectedTime: getTimeSlot(
  //       moment(props?.data?.weekCalendar).format("DD-MM-YYYY")
  //     ),
  //   };

  //   let oldSlotData;
  //   console.log("slots", slots);
  //   slots &&
  //     slots?.forEach((slot) => {
  //       if (slot) {
  //         let {
  //           start,
  //           _id: slotId,
  //           status,
  //           end,
  //           isdirect = false,
  //           issuggest = false,
  //           isvideo = false,
  //           Maxbooking = 0,
  //           Maxwaiting = 0,
  //           bookedCount = 0,
  //           maxgeneral = 0,
  //           maxwalkin = 0,
  //           appointmentType,
  //           consulttype,
  //           resourcecode,
  //           resourcerole,
  //           resourcetype,

  //           _id,
  //         } = slot;
  //         //console.log("consulttype" , consulttype?.display)
  //         console.log("preferedslottype", slot?.preferedslottype);
  //         const preferedslottype = slot?.preferedslottype
  //           .split(",")
  //           .map((a) => a.toLowerCase());

  //         if (status !== "closed" && status !== "booked") {
  //           //const slotTime = new Date(start * 1000);
  //           const slotTime = utcTOLocal(start).toDate();
  //           const hours = slotTime.getHours();
  //           let label = getTimeString(hours, slotTime.getMinutes());
  //           if (isClinic) {
  //             const endTime = new Date(end * 1000);
  //             const endHours = endTime.getHours();
  //             const endTimeLabel = getTimeString(
  //               endHours,
  //               endTime.getMinutes()
  //             );
  //             label = `${label} - ${endTimeLabel}`;
  //           }
  //           if (
  //             preferedslottype.includes("direct") ||
  //             preferedslottype.includes("both")
  //           ) {
  //             isdirect = true;
  //           }
  //           if (
  //             preferedslottype.includes("video") ||
  //             preferedslottype.includes("both")
  //           ) {
  //             isvideo = true;
  //           }
  //           const iconsArr = [];
  //           // const icon = <VideoConsultation />;
  //           if (isdirect) {
  //             iconsArr.push(icons["direct"]);
  //           }
  //           if (isvideo) {
  //             iconsArr.push(icons["video"]);
  //           }
  //           if (issuggest) {
  //             iconsArr.push(icons["suggested"]);
  //           }
  //           const slotData = {
  //             value: slot?._id,
  //             label,
  //             _id,
  //             date: slotTime.getTime(),
  //             dateEnd: end,
  //             dateStart: start,
  //             status,
  //             isdirect,
  //             issuggest,
  //             isvideo,
  //             booked: bookedCount,
  //             maxBooking: Maxbooking,
  //             maxWaiting: Maxwaiting,
  //             waiting: bookedCount > Maxbooking ? bookedCount - Maxbooking : 0,
  //             icon: iconsArr,
  //             healthCareId,
  //             maxgeneral,
  //             isWalkin:
  //               moment()
  //                 .startOf("day")
  //                 .diff(moment(start * 1000), "d") === 0
  //                 ? true
  //                 : false,
  //             maxwalkin,
  //             resourcecode,
  //             resourcerole,
  //             resourcetype,
  //             appointmentType,
  //           };
  //           if (isClinic) {
  //             allSession.push(slotData);
  //           } else {
  //             if (slot?.DayType?.display?.toLowerCase() === "morning") {
  //               morning.push(slotData);
  //             } else if (
  //               slot?.DayType?.display?.toLowerCase() === "afternoon"
  //             ) {
  //               afternoone.push(slotData);
  //             } else if (slot?.DayType?.display?.toLowerCase() === "evening") {
  //               evening.push(slotData);
  //             } else if (slot?.DayType?.display?.toLowerCase() === "night") {
  //               night.push(slotData);
  //             } else {
  //               morning.push(slotData);
  //             }
  //           }
  //           if (isUpdate) {
  //             if (slotId === selectedSlot) {
  //               oldSlotData = {
  //                 isUpdate: true,
  //                 info: slotData,
  //                 hospitalInfo: props.data,
  //                 appInfoFetched: {},
  //               };
  //               obj.selectedTime = getTimeSlot(start, "unix", true);
  //             }
  //           }
  //         }
  //       }
  //     });
  //   if (isUpdate) {
  //     if (!oldSlotData) {
  //       const {
  //         start,
  //         _id: slotId,
  //         status,
  //         end,
  //         isdirect = false,
  //         issuggest = false,
  //         isvideo = false,
  //         Maxbooking = 0,
  //         Maxwaiting = 0,
  //         bookedCount = 0,
  //         maxgeneral = 0,
  //         maxwalkin = 0,
  //         _id,
  //       } = props.oldAppointment?.Slots?.[0] || {};
  //       const slotTime = start ? new Date(start * 1000) : new Date();
  //       const hours = slotTime.getHours();
  //       let label = getTimeString(hours, slotTime.getMinutes());
  //       const slotInfo = {
  //         value: slotId,
  //         label,
  //         _id,
  //         date: slotTime.getTime(),
  //         dateEnd: end,
  //         dateStart: start,
  //         status,
  //         isdirect,
  //         issuggest,
  //         isvideo,
  //         booked: bookedCount,
  //         maxBooking: Maxbooking,
  //         maxWaiting: Maxwaiting,
  //         waiting:
  //           bookedCount > Maxbooking ? bookedCount - Maxbooking : Maxwaiting,
  //         icon: [],
  //         healthCareId,
  //         maxgeneral,
  //         isWalkin:
  //           moment()
  //             .startOf("day")
  //             .diff(moment(start * 1000), "d") === 0
  //             ? true
  //             : false,
  //         maxwalkin,
  //       };
  //       oldSlotData = {
  //         isUpdate: true,
  //         info: slotInfo,
  //         hospitalInfo: props.data,
  //         appInfoFetched: {},
  //       };
  //     }
  //     props?.handleChange("oldSlot", oldSlotData);
  //   }
  //   return obj;
  // };

  // const tempdata = JSON.parse(JSON.stringify(props.data));
  // const obj = filterSlots(
  //   tempdata.resourceInfo,
  //   tempdata.slots,
  //   tempdata.morning,
  //   tempdata.afternoone,
  //   tempdata.evening,
  //   tempdata.night,
  //   tempdata.allSession,
  //   tempdata.isClinic,
  //   tempdata.healthCareId,
  //   tempdata.isUpdate,
  //   tempdata.selectedSlot
  // );

  const [state, setState] = useState({
    // data: { ...tempdata } || {},
    date: moment(
      props.selectedDate ? props.selectedDate : props.data?.weekCalendar
    ).format("MM-DD-YYYY"),
    tempdate: moment(
      props.selectedDate ? props.selectedDate : props.data?.weekCalendar
    ).format("MM-DD-YYYY"),
    showCotButton: false,
    // ...obj,
    slotSelect: null,
    openCloseCalendar: false,
    monthCalendarInputValue: "",
    openDoctorProfile: false,
    openClinicProfile: false,
    dirty: false,
    clinicData: {},
    typeOfSession:""
  });
  // useEffect(() => {
  //   const obj = {};
  //   const tempdata = {};
  //   setState((prevState) => ({
  //     ...prevState,
  //     data: { ...tempdata },
  //     ...obj,
  //   }));
  // }, [props?.data, props?.selectedDate]);
  const open = Boolean(state.openCloseCalendar);
  const id = open ? "simple-popover" : undefined;
  const { data, selectedTime, clinicData } = state;
  // Functions
  const changeState = async (key, value, _key,typeOfSession) => {
    if (key === "openClinicProfile") {
      const data = await dispatch(actions.READ_CLINICINFO({ key: _key }));
      setState((prevState) => ({
        ...prevState,
        clinicData: data?.payload,
        [key]: value,
      }));

      setState({
        ...state,
        typeOfSession:typeOfSession,
        [key]: value,
      });
    }
  };

  const updateSlot = (info, date) => {
    const slot = JSON.parse(JSON.stringify(info));
    filterSlots(
      slot.resourceInfo,
      slot.slots,
      slot.morning,
      slot.afternoone,
      slot.evening,
      slot.night,
      slot.allSession,
      slot.isClinic,
      slot.healthCareId
    );
    const statedata = { ...state };
    statedata.data.morning = slot.morning;
    statedata.data.afternoone = slot.afternoone;
    statedata.data.evening = slot.evening;
    statedata.data.night = slot.night;
    statedata.data.allSession = slot.allSession;
    statedata.date = date;
    statedata.selectedTime = getTimeSlot(date);
    setState(statedata);
  };

  const handleClose = (val) => {
    setState((prevState) => ({
      ...prevState,
      openCloseCalendar: val,
    }));

    // setState({
    //   ...state,
    //   openCloseCalendar: val,
    // });
  };

  const handlepapoverclose = (val) => {
    if (val) {
      setState({ opencalendar: null });
    }
  };

  const onKeyDown = (e) => {
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        e.keyCode === 8 ||
        e.keyCode === 46 ||
        e.keyCode === 37 ||
        e.keyCode === 39
      )
    ) {
      e.preventDefault();
    }
  };
  async function handleApply() {
    const { tempdate, monthCalendarInputValue, data } = state;
    let date = tempdate;
    if (monthCalendarInputValue) {
      date = moment(
        new Date(
          moment(tempdate, "MM-DD-YYYY").add(
            parseInt(monthCalendarInputValue),
            "days"
          )
        )
      ).format("MM-DD-YYYY");
    }
    const startdate = moment(date).startOf("day");
    const enddate = moment(date).endOf("day");

    const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);
    const endDate = new Date(currentDate);
    endDate.setHours(23, 59, 59, 999);
    const endEpochTime = getEpochTime(endDate);

    const slotInfo = await dispatch(
      actions.READ_SLOT({
        id: data?.isClinic ? data.clinicId : data._id,
        startdate: getUtcTime(startdate), //startEpochTime,
        enddate: getUtcTime(enddate), //endEpochTime,
        type: data?.isClinic ? "clinicid" : "practionerid",
        practitionerid: props?.resource_id,

        //facilityid: location?.state?.facilityid,
      })
    );
    if (slotInfo?.payload?.data) {
      updateSlot(slotInfo?.payload?.data, date);
    }
    setState(
      (prevState) => ({
        ...prevState,
        date,
      }),
      () => {
        handleClose(false);
      }
    );

    // setState({ ...state, date }, () => {
    //   handleClose(false);
    // });
  }

  const filterCalendar = (e) => {
    setState((prevState) => ({
      ...prevState,
      openCloseCalendar: e.currentTarget,
    }));

    // setState({
    //   ...state,
    //   openCloseCalendar: e.currentTarget,
    // });
  };

  async function handleSlotAvailability(obj,typeOfSession) {
    if (obj._id) {
      changeState("dirty", true,"",typeOfSession);
      const data = await dispatch(
        actions.SLOT_AVAILABILITY({ slotId: obj._id })
      );
      console.log("slotdata", data);
      const { isError, errMsg } = checkError(data?.payload);
      if (!isError) {
        if (
          Array.isArray(data?.payload?.data) &&
          data.payload.data[0]?.display !== "Blocked" &&
          data.payload.data[0]?.display !== "Booked"
        ) {
          const selectedSlot = {
            info: JSON.parse(JSON.stringify(obj)),
            hospitalInfo: JSON.parse(JSON.stringify(props.data)),
          };
          setState((prevState) => ({
            ...prevState,
            selectedTimeSlot: selectedSlot,
          }));

          // setState({
          //   ...state,
          //   selectedTimeSlot: selectedSlot,
          // });
          // props.handleChange("selectedSlot", selectedSlot);
          props.handleChange("selectedSlotId", obj._id);
          props.handleChange("selectedSlotOrd", props.data._id);
        } else {
          alert("Slot is Overbooked");
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          selectedTimeSlot: {},
          slotSelect: "",
        }));

        // setState({
        //   ...state,
        //   selectedTimeSlot: {},
        //   slotSelect: "",
        // });
        console.error(errMsg);
      }
      setState((prevState) => ({
        ...prevState,
        showCotButton: true,
        // selectedTimeSlot: {},
        slotSelect: obj._id,
      }));

      // setState({
      //   ...state,
      //   showCotButton: true,
      //   // selectedTimeSlot: {},
      //   slotSelect: obj.value,
      // });
    } else {
      setState((prevState) => ({
        ...prevState,
        showCotButton: false,
        selectedTimeSlot: {},
        slotSelect: "",
      }));

      // setState({
      //   ...state,
      //   showCotButton: false,
      //   selectedTimeSlot: {},
      //   slotSelect: "",
      // });
      props.handleChange("selectedSlot", {});
    }
  }

  const funtionClick = async () => {
    await Promise.all([
      dispatch(
        actions.GENERAL_INSTRUCTION({
          orgid: props?.data?.org_Id,
          apptypecode: "CodingMaster/10003",
        })
      ),
    ]);

    props.rightSideAllCardfun(true, state.selectedTimeSlot);
    setPatientDatacontext((prevtstate) => ({
      ...prevtstate,
      selectedSlot: state.selectedTimeSlot,
    }));
    console.log("state.selectedTimeSlot", state.selectedTimeSlot);
    props.resetMulti();
    // setState((prevState) => ({
    //   ...prevState,
    //   showCotButton: false,
    // }));

    setState({
      ...state,
      showCotButton: false,
    });
  };
  function dateToEpoch(dateStr) {
    const [month, day, year] = dateStr.split('-').map(num => parseInt(num));
    const startDate = new Date(year, month - 1, day, 0, 0, 0); 
    const endDate = new Date(year, month - 1, day, 23, 59, 59);  
    return {
        start: startDate.getTime() / 1000,
        end: endDate.getTime() / 1000
    };
}
  const watingListCount = useSelector((state) => state?.slot?.watingListAvailability);
  const PayloadGenerator = async (data) => {
    debugger
    let date = new Date(state?.date);
    let [hours, minutes] = selectedSlotEndTiming?.split(':').map(Number);
    date.setHours(hours, minutes, 0, 0);
    let preferredDate = Math.floor(date.getTime() / 1000);
    
    let doc ={
        followupdate: 0,
        status: " ",
        encounterid: " ",
        practitionerid: decodedUserinfo?.practitionerid,
        orgid: decodedUserinfo?.facilityid,
        healthserviceid: " ",
        patientid:location?.state?.patientId,
        specialtyid: props?.data?.specialities?.[0]?.id,
        encounter_type: " ",
        orderid: "",
        followupdurval: 0,
        followupduruom: "",
        encounterdate: 0,
        appointmentid: "",
        appointmenttype:waitListAppointmentId?.waitList,
        followupreason: "",
        personid:location?.state?.userId,
        facilityid: decodedUserinfo?.facilityid,
        transactiondate:state?.tempdate ? dateToEpoch(state?.tempdate).start : 0,
        preferreddate: preferredDate,//dateToEpoch(state?.date),
        requestuser: location?.state?.patientId,
        doctor: props?.resource_id,
        daytype:appointmentdurationId,
        waitinglistseq:watingListCount?.data?.[0]?.sequence,
        status:waitListAppointmentId?.status,
        reasonCode:[],
        resourcetype:state?.data?.schedule?.[0]?.resourcetype
      };
    let payload = {
      db_name: process.env.REACT_APP_DB,
      entity: "AppointmentRequest",
      is_metadata: true,
      metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
      metadataId: process.env.REACT_APP_METADATAID,
      doc: doc,
    };
    return payload;
  };
  const HandleWatingList = async () => {
    setIsWatingListButtonClick(true)
    let payload = await PayloadGenerator();
    let saveWatingList = await dispatch(actions.SAVE_WAITING_LIST(payload));
    let WaitListData = saveWatingList?.payload?.data?.response;

      if (WaitListData && !WaitListData?.error) {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "The patient is scheduled on the wait list",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
            navigate(AppRoutes.PatientDashBoard, {
              state: {
                userId: location?.state?.userId,
                patientId:location?.state?.patientId,
              },
            });
            setIsWatingListButtonClick(false);
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: WaitListData?.message || "something went wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        setIsWatingListButtonClick(false);
      }
  };
const handleTabChange = (event) => {
  let title = '';
  switch (event) {
    case 0:
      title = 'DAY001';
      break;
    case 1:
      title = 'DAY002';
      break;
    case 2:
      title = 'DAY003';
      break;
    case 3:
      title = 'DAY004';
      break;
    default:
      title = 'DAY001';

      break;
  }
  setSelectedTabTitle(title);
  availableWatingList(title);
};
const availableWatingList = async (type) =>{
  const slotInfoData = state?.data?.schedule?.[0]?.appointmentperiod?.[0]
  const matchingAppointments = slotInfoData?.appointmentdurationid
  ?.find(appointment => appointment?.daytype?.code === (type || selectedTabTitle))
  ?._id;
  const matchingSlotEndTiming = slotInfoData?.appointmentdurationid
  ?.find(appointment => appointment?.daytype?.code === (type || selectedTabTitle))
  ?.endtime;
  setSelectedSlotEndTiming(matchingSlotEndTiming)
  setAppointmentdurationId(matchingAppointments)
  let payload = {
    practitionerid: [props?.resource_id],
    daytype: type ?? selectedTabTitle,
    start: state?.date ? dateToEpoch(state?.date).start : null,  
    end: state?.date ? dateToEpoch(state?.date).end : null,
    resourcetimetableid: state?.data?.schedule?.[0]?._id ?? props?.data?.slots?.[0]?.timetable_id
  };
  
const watingListCount = await dispatch(
  actions.WATING_LIST_AVAILABILITY(payload)
);
setMaxWatingListCount(watingListCount?.payload?.data);
}
  useEffect(() => {
    availableWatingList();
  }, [props?.resource_id,state?.date,state?.data?.schedule]);
let count = maxWatingListCount?.[0]
  return (
    <Div>
      <Row id={`${parent_id}_doctorcard_weekcalender_row`} key={"0"}>
        <Col
          id={`${parent_id}_doctorcard_weekcalender_format_col`}
          key={"0"}
          name="qdm-col-11"
          alignItems="stretch"
          direction="column"
          justify="flex-start"
          textAlign="left"
          lg="11"
          xs="11"
          md="11"
          sm="11"
          style={{ padding: "0px", position: "relative" }}
        >
          {/* <WeekCalendar
  id={`${parent_id}_doctorcard_weekcalender`}
  activeColor={"#2A60BC"}
  key={"0"}
  weekStartDate={() =>
    convertTime(Math.floor(Date.now() / 1000), "unix", "MM-DD-YYYY")
  }
  style={{ borderWidth: "0px", fontSize: 14 }}
  className={classes.weekcalendar}
  onChange={async (date) => {
    const selectedDate = moment(date).startOf("day");
    const stateStartDate = moment(state.date, "MM-DD-YYYY").startOf("day");

    if (selectedDate.isBefore(stateStartDate)) {
      alert("You cannot select a date before the start of the calendar.");
      return;
    }

    const enddate = moment(date).endOf("day");
    const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);
    const endDate = new Date(currentDate);
    endDate.setHours(23, 59, 59, 999);
    const endEpochTime = getEpochTime(endDate);

    const slotInfo = await dispatch(
      actions.READ_SLOT({
        id: data?.isClinic ? data.clinicId : data.id,
        startdate: getUtcTime(startDate),
        enddate: getUtcTime(endDate),
        type: data?.isClinic ? "clinicid" : "practionerid",
        practitionerid: props?.resource_id,
      })
    );

    if (slotInfo?.payload?.data) {
      updateSlot(slotInfo?.payload?.data, date);
    }
  }}
  currentDate={state?.date}
  minDate={state?.date}
  disabledDays={(date) => moment(date).isBefore(state.date)}
/> */}
          <WeekCalendar
            id={`${parent_id}_doctorcard_weekcalender`}
            activeColor={"#2A60BC"}
            key={"0"}
            weekStartDate={convertTime(
              Math.floor(Date.now() / 1000),
              "unix",
              "MM-DD-YYYY"
            )}
            // weekStartDate={"06-11-2021"}
            style={{ borderWidth: "0px", fontSize: 14 }}
            className={classes.weekcalendar}
            onChange={async (date) => {
              const startdate = moment(date).startOf("day");
              const enddate = moment(date).endOf("day");
              const slotInfo = await dispatch(
                actions.READ_SLOT({
                  id: data?.isClinic ? data.clinicId : data._id,
                  startdate: getUtcTime(startdate),
                  enddate: getUtcTime(enddate),
                  type: data?.isClinic ? "clinicid" : "practionerid",
                  practitionerid: props?.resource_id,
                })
              );
              if (slotInfo?.payload?.data) {
                updateSlot(slotInfo?.payload?.data, date);
              }
            }}
            currentDate={state.date}
            // dateValue={this.state.date}
          />
        </Col>
        <Col
          id={`${parent_id}_doctorcard_pop_calender_col`}
          lg="1"
          xs="1"
          md="1"
          sm="1"
          inLineStyles={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 0,
          }}
        >
          <Div
            id={`${parent_id}_doctorcard_pop_calender_div`}
            style={{ height: 30, width: 2, backgroundColor: "#F0F0F0" }}
          />
          <Popup
            id={id}
            open={false}
            anchorEl={state.openCloseCalendar}
            onClose={() => handleClose(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{ marginTop: "1%", marginLeft: "2%" }}
          >
            <div
              id={`${parent_id}_doctorcard_calender_div`}
              style={{
                width: 300,
                backgroundColor: "#F9F9F9",
                padding: "10px",
              }}
            >
              {/* <Calendars
                                          id={`${parent_id}_doctorcard_pop_calender`}
                                          minDate={new Date()}
                                          allowPartialRange
                                          value={new Date(state.tempdate)}
                                          onClickMonth={(value, event) => {
                                              handlepapoverclose(false);
                                          }}
                                          onClickYear={(value, event) => {
                                              handlepapoverclose(false);
                                          }}
                                          onClickWeekNumber={(value, event) => {
                                              handlepapoverclose(false);
                                          }}
                                          className={classes.monthCalendar}
                                          onChange={(value, event) => {
                                              const milli = value.getTime();
                                              const oldVal = state?.age?.milli;
                                              const oldDate = moment(oldVal).format("DD");
                                              const newDate = moment(milli).format("DD");
                                              const date = moment(value).format("MM-DD-YYYY");
                                              changeState("tempdate", date);
                                              if (oldDate !== newDate || milli === oldVal) {
                                                  handlepapoverclose(true);
                                              }
                                          }}
                                      /> */}
              <CustomDatePicker
                minDate={new Date()}
                selectedDate={new Date(state.tempdate)}
                handleChange={(value) => {
                  const milli = value.getTime();
                  const oldVal = state?.age?.milli;
                  const oldDate = moment(oldVal).format("DD");
                  const newDate = moment(milli).format("DD");
                  const date = moment(value).format("MM-DD-YYYY");
                  changeState("tempdate", date);
                  if (oldDate !== newDate || milli === oldVal) {
                    handlepapoverclose(true);
                  }
                }}
              />
            </div>
            <div
              id={`${parent_id}_doctorcard_or_div`}
              style={{
                backgroundColor: "#F9F9F9",
              }}
            >
              <Divider
                id={`${parent_id}_doctorcard_or_divider`}
                variant="fullWidth"
                textWidth={0}
                label={"or"}
                textColor="#B6B6B6"
                borderColor="#B6B6B6"
                className={classes.divider}
              />
            </div>
            <div
              id={`${parent_id}_doctorcard_description_div`}
              style={{
                backgroundColor: "#F9F9F9",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "3%",
              }}
            >
              <div
                id={`${parent_id}_doctorcard_description_earliest_div`}
                style={{
                  backgroundColor: "#fff",
                  padding: "4px",
                  border: "1px solid #E0E0E0",
                  borderRadius: 4,
                  width: "94%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                <span
                  id={`${parent_id}_doctorcard_earliest_span`}
                  style={{ fontSize: 14 }}
                >
                  Earliest available after
                </span>{" "}
                <input
                  id={`${parent_id}_doctorcard_earliest_input`}
                  style={{
                    width: 40,
                    height: 24,
                    border: "1px solid #e0e0e0",
                    outline: "none",
                    borderRadius: 3,
                  }}
                  className={classes.earliestInput}
                  value={state.monthCalendarInputValue}
                  placeholder="000"
                  maxLength={3}
                  onChange={(e) =>
                    changeState("monthCalendarInputValue", e.target.value)
                  }
                  onKeyDown={(e) => onKeyDown(e)}
                />{" "}
                <span
                  id={`${parent_id}_doctorcard_days_span`}
                  style={{ fontSize: 14, fontWeight: 600 }}
                >
                  Days
                </span>
              </div>
            </div>
            <div
              id={`${parent_id}_doctorcard_button_div`}
              style={{ backgroundColor: "#F9F9F9" }}
            >
              <div
                id={`${parent_id}_doctorcard_button_clear_div`}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <button
                  id={`${parent_id}_doctorcard_clear_button`}
                  className={classes.clear}
                  onClick={() => changeState("monthCalendarInputValue", "")}
                >
                  Clear
                </button>
                <button
                  id={`${parent_id}_doctorcard_apply_button`}
                  className={classes.apply}
                  onClick={() => handleApply()}
                >
                  Apply
                </button>
              </div>
            </div>
          </Popup>
          <Div
            id={`${parent_id}_doctorcard_filter_div`}
            style={{ marginRight: 14 }}
            onClick={(e) => filterCalendar(e)}
          >
            <Calendar
              id={`${parent_id}_doctorcard_filter_calender`}
              color={false ? "#0071F2" : "#6f6f6f"}
            />
          </Div>
        </Col>
      </Row>

      <Col
        id={`${parent_id}_doctorcard_all_session_col`}
        key={"1"}
        name="qdm-col-12"
        alignItems="stretch"
        direction="row"
        justify="flex-start"
        textAlign="left"
        lg="12"
        xs="12"
        md="12"
        sm="12"
        style={{ marginTop: "14px", paddingLeft: 0, paddingRight: 0 }}
      >
        {!!data?.isAllSession ? (
          <div
            id={`${parent_id}_doctorcard_all_session_label_div`}
            key={"0"}
            label="ALL SESSION"
            title="ALL SESSION"
            style={{ minHeight: "23vh", marginBottom: "8%" }}
          >
            <div id={`${parent_id}_doctorcard_all_session_label_sub_div`}>
              <span
                id={`${parent_id}_doctorcard_all_session_label_span`}
                style={{
                  color: "#0071F2",
                  display: "block",
                  fontSize: "14px",
                  marginTop: "2px",
                  borderBottom: "2px solid #0071f2",
                  width: "24%",
                  textAlign: "center",
                  paddingBottom: 4,
                  fontFamily: "pc_medium",
                }}
              >
                ALL SESSION
              </span>
            </div>
            {data?.allSession.length > 0 ? (
              data?.allSession.map((val, i) => (
                <TapComp
                  parent_id={"doctor_listing_carddetails_" + i}
                  type="clinic"
                  val={val}
                  dirty={state.dirty}
                  selectedId={props?.data?.selectedSlot || 0}
                  //stateVal={state.slotSelect}
                  stateVal={props.selectedSlotId}
                  isSelected={props.selectedSlotOrd === props.data._id}
                  onChange={() => handleSlotAvailability(val)}
                  rightSideAllCard={props.rightSideAllCard}
                />
              ))
            ) : (
              <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "25vh",
                textAlign: "center",
              }}
            >
             {count?.balancewaitinglistcount > 0? (
               <Button
               style={{ backgroundColor: "#f29513", color: "white" }}
                onClick={HandleWatingList}
                disabled={isWatingListButtonClick}
                >
                  {isWatingListButtonClick 
                  ? "Join Waitlist..." 
                  : `Join Waitlist(${count?.balancewaitinglistcount}/${count?.maxwaitinglistcount})`}
               </Button>
              ) : (
                // <div
                //   style={{
                //     textAlign: "center",
                //     fontWeight: "bold",
                //     fontSize: "18px",
                //   }}
                // >
                //   <p>Slots not available</p>
                // </div>
                <Button
                style={{ backgroundColor: "#F45156", color: "white" }}
                 disabled={true}
                 >
                   Join WaitList({count?.balancewaitinglistcount??0}/{count?.maxwaitinglistcount??0})
                </Button>
              )}
            </div>
            )}
          </div>
        ) : (
          <Tab
            id={`${parent_id}_doctorcard_all_session_tap`}
            className={classes.tabs}
            key={"0"}
            align="center"
            activetabs={selectedTime}
            name=""
            label=""
            inLineTabTitleStyles=""
            fullWidth={true}
            size="small"
            backgroundColor="#3f51b5"
            elevation="0"
            color=""
            border={false}
            variant="withoutBordered"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            activeColor={"#2A60BC"}
            onChange={handleTabChange} 
            >
            <Tabpanel
              id={`${parent_id}_doctorcard_MORNING_tabpanel`}
              key={"0"}
              label="MORNING"
              title="MORNING"
              subTitle={`${data?.morning?.length || "No"} slots`}
              color={data?.morning?.length ? "#38C20A" : "#EA1601"}
              style={{
                minHeight: "16vh",
                marginBottom: "12%",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              {data?.morning?.length > 0 ? (
                data?.morning?.map((val, i) => (
                  <TapComp
                    parent_id={"doctor_listing_carddetails_" + i}
                    val={val}
                    dirty={state.dirty}
                    selectedId={props?.data?.selectedSlot || 0}
                    stateVal={props.selectedSlotId}
                    isSelected={props.selectedSlotOrd === props.data._id}
                    onChange={() => handleSlotAvailability(val,"MORNING")}
                  />
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "25vh",
                    textAlign: "center",
                  }}
                >
                {count?.balancewaitinglistcount > 0? (
               <Button
               style={{ backgroundColor: "#f29513", color: "white" }}
                onClick={HandleWatingList}
                disabled={isWatingListButtonClick}
                >
                   {isWatingListButtonClick 
                  ? "Join Waitlist..." 
                  : `Join Waitlist(${count?.balancewaitinglistcount}/${count?.maxwaitinglistcount})`}
               </Button>
                  ) : (
                    // <div
                    //   style={{
                    //     textAlign: "center",
                    //     fontWeight: "bold",
                    //     fontSize: "18px",
                    //   }}
                    // >
                    //   <p>Slots not available</p>
                    // </div>
                    <Button
                    style={{ backgroundColor: "#F45156", color: "white" }}
                     disabled={true}
                     >
                       Join WaitList({count?.balancewaitinglistcount??0}/{count?.maxwaitinglistcount??0})
                    </Button>
                  )}
                </div>
              )}
            </Tabpanel>

            <Tabpanel
              id={`${parent_id}_doctorcard_AFTERNOON_tabpanel`}
              key={"1"}
              label="AFTERNOON"
              title="AFTERNOON"
              subTitle={`${data?.afternoone?.length || "No"} slots`}
              color={data?.afternoone?.length ? "#38C20A" : "#FF4F4A"}
              style={{
                minHeight: "16vh",
                marginBottom: "12%",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              {data?.afternoone?.length > 0 ? (
                data?.afternoone?.map((val, i) => (
                  <TapComp
                    parent_id={"doctor_listing_carddetails_" + i}
                    val={val}
                    dirty={state.dirty}
                    selectedId={props?.data?.selectedSlot || 0}
                    // stateVal={state.slotSelect}
                    stateVal={props.selectedSlotId}
                    isSelected={props.selectedSlotOrd === props.data._id}
                    onChange={() => handleSlotAvailability(val,"AFTERNOON")}
                  />
                ))
              ) : (
                // <div
                //   style={{
                //     textAlign: "center",
                //     fontWeight: "bold",
                //     fontSize: "18px",
                //   }}
                // >
                //   <p>Slots not available</p>
                // </div>
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "25vh",
                  textAlign: "center",
                }}
              >
               {count?.balancewaitinglistcount > 0? (
               <Button
               style={{ backgroundColor: "#f29513", color: "white" }}
                onClick={HandleWatingList}
                disabled={isWatingListButtonClick}
                >
                    {isWatingListButtonClick 
                  ? "Join Waitlist..." 
                  : `Join Waitlist(${count?.balancewaitinglistcount}/${count?.maxwaitinglistcount})`}
               </Button>
                ) : (
                  // <div
                  //   style={{
                  //     textAlign: "center",
                  //     fontWeight: "bold",
                  //     fontSize: "18px",
                  //   }}
                  // >
                  //   <p>Slots not available</p>
                  // </div>
                  <Button
                  style={{ backgroundColor: "#F45156", color: "white" }}
                   disabled={true}
                   >
                     Join WaitList({count?.balancewaitinglistcount??0}/{count?.maxwaitinglistcount??0})
                  </Button>
                )}
              </div>
              )}
            </Tabpanel>

            <Tabpanel
              id={`${parent_id}_doctorcard_EVENING_tabpanel`}
              key={"2"}
              label="EVENING"
              title="EVENING"
              subTitle={`${data?.evening?.length || "No"} slots`}
              color={data?.evening?.length ? "#38C20A" : "#FF4F4A"}
              style={{
                minHeight: "16vh",
                marginBottom: "12%",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              {data?.evening?.length > 0 ? (
                data?.evening?.map((val, i) => (
                  <TapComp
                    parent_id={"doctor_listing_carddetails_" + i}
                    val={val}
                    dirty={state.dirty}
                    stateVal={props.selectedSlotId}
                    isSelected={props.selectedSlotOrd === props.data._id}
                    // stateVal={state.slotSelect}
                    selectedId={props.data.selectedSlot || 0}
                    onChange={() => handleSlotAvailability(val,"EVENING")}
                  />
                ))
              ) : (
                // <div
                //   style={{
                //     textAlign: "center",
                //     fontWeight: "bold",
                //     fontSize: "18px",
                //   }}
                // >
                //   <p>Slots not available</p>
                // </div>
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "25vh",
                  textAlign: "center",
                }}
              >
               {count?.balancewaitinglistcount > 0? (
               <Button
               style={{ backgroundColor: "#f29513", color: "white" }}
                onClick={HandleWatingList}
                disabled={isWatingListButtonClick}
                >
                    {isWatingListButtonClick 
                  ? "Join Waitlist..." 
                  : `Join Waitlist(${count?.balancewaitinglistcount}/${count?.maxwaitinglistcount})`}
               </Button>
                ) : (
                  // <div
                  //   style={{
                  //     textAlign: "center",
                  //     fontWeight: "bold",
                  //     fontSize: "18px",
                  //   }}
                  // >
                  //   <p>Slots not available</p>
                  // </div>
                  <Button
                  style={{ backgroundColor: "#F45156", color: "white" }}
                   disabled={true}
                   >
                     Join WaitList({count?.balancewaitinglistcount??0}/{count?.maxwaitinglistcount??0})
                  </Button>
                )}
              </div>
              )}
            </Tabpanel>

            <Tabpanel
              id={`${parent_id}_doctorcard_NIGHT_tabpanel`}
              key={"3"}
              label="NIGHT"
              title="NIGHT"
              subTitle={`${data?.night?.length || "No"} slots`}
              color={data?.night?.length ? "#38C20A" : "#FF4F4A"}
              style={{
                minHeight: "16vh",
                marginBottom: "12%",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              {data?.night?.length ? (
                data?.night?.map((val, i) => (
                  <TapComp
                    parent_id={"doctor_listing_carddetails_" + i}
                    val={val}
                    dirty={state.dirty}
                    selectedId={props?.data?.selectedSlot || 0}
                    isSelected={props.selectedSlotOrd === props.data._id}
                    // stateVal={state.slotSelect}
                    stateVal={props.selectedSlotId}
                    onChange={() => handleSlotAvailability(val,"NIGHT")}
                  />
                ))
              ) : (
                // <div
                //   style={{
                //     textAlign: "center",
                //     fontWeight: "bold",
                //     fontSize: "18px",
                //   }}
                // >
                //   <p>Slots not available</p>
                // </div>
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "25vh",
                  textAlign: "center",
                }}
              >
              {count?.balancewaitinglistcount > 0? (
               <Button
               style={{ backgroundColor: "#f29513", color: "white" }}
                onClick={HandleWatingList}
                disabled={isWatingListButtonClick}
                >
                    {isWatingListButtonClick 
                  ? "Join Waitlist..." 
                  : `Join Waitlist(${count?.balancewaitinglistcount}/${count?.maxwaitinglistcount})`}
               </Button>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    <p>Slots not available</p>
                  </div>
                )}
              </div>
              )}
            </Tabpanel>
          </Tab>
        )}
      </Col>

      <Col
        id={`${parent_id}_doctorcard_bottom_col`}
        key={"2"}
        name="qdm-col-12"
        alignItems="stretch"
        direction="row"
        justify="flex-start"
        textAlign="left"
        lg="12"
        xs="12"
        md="12"
        sm="12"
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "0px",
          position: "absolute",
          bottom: 0,
          backgroundColor: "#fff",
        }}
      >
        {data?.isClinic ? null : (
          <>
            <Div
              id={`${parent_id}_doctorcard_Video_Consultation_div`}
              key={"1"}
            >
              <Icons
                id={`${parent_id}_doctorcard_Video_camera_icons`}
                key={"0"}
                fontIcon="video-camera"
                ariaHidden="true"
                size="small"
                style={{ color: "#6F6F6F" }}
              ></Icons>

              <Text
                id={`${parent_id}_doctorcard_Video_Consultation_text`}
                key={"1"}
                name="Video Consultation"
                className="pc_regular"
                style={{
                  marginLeft: "8px",
                  color: "#6F6F6F",
                  fontSize: 12,
                  letterSpacing: 0,
                }}
              ></Text>
            </Div>

            <Div
              id={`${parent_id}_doctorcard_Direct_Consultation_div`}
              key={"2"}
            >
              <Icons
                id={`${parent_id}_doctorcard_user_icons`}
                key={"0"}
                fontIcon="user"
                ariaHidden="true"
                size="small"
                style={{ color: "#6F6F6F" }}
              ></Icons>

              <Text
                id={`${parent_id}_doctorcard_Direct_Consultation_text`}
                key={"1"}
                name="Direct Consultation"
                className="pc_regular"
                style={{
                  marginLeft: "8px",
                  color: "#6F6F6F",
                  fontSize: 12,
                  letterSpacing: 0,
                }}
              ></Text>
            </Div>
          </>
        )}
      </Col>
      {state?.showCotButton && props?.selectedSlotOrd === props?.data?._id ? (
        <div
          id={`${parent_id}_doctorcard_Continue_Booking_div`}
          style={{
            width: "100%",
            padding: "0px",
            backgroundColor: "#ffffffbf",
            position: "absolute",
            bottom: "0px",
          }}
        >
          <Button
            id={`${parent_id}_doctorcard_Continue_Booking_button`}
            // key={"7"}
            type="button"
            variant="outlined"
            name="Continue Booking"
            onClick={() => funtionClick()}
            style={{
              margin: "auto",
              backgroundColor: "#2A60BC",
              borderColor: "#2A60BC",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              padding: "8px 14px",
              cursor: "pointer",
              marginTop: 8,
              fontSize: 14,
            }}
          ></Button>
        </div>
      ) : (
        ""
      )}
    </Div>
  );
};

export default SlotCalandar;
