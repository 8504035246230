import React, { useEffect, useState } from 'react'
import { FnExecutorBasedOnFLow, getGlobalValueFrom_LclStrg_And_QryStrg } from '../../utils';
import Dashboard from '../dashboard';
import Queue from '../Queue/queue';
import PatientDashboard from '../patientDashboard/patientDashboard';
import { useNavigate } from 'react-router-dom';

function Dashboards() {
  const navigate = useNavigate();
  const decodedUserinfo = getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "UserInfo", isFullObject: true });
  const decodePermissionData = getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "PermissionData", isFullObject: true });
  const { userid, phoneno, patientid } = decodedUserinfo || {};
  const startupRoute = Object.values(decodePermissionData).find(item => item?.startup === true)?.routes;
  console.log(startupRoute,"startupRoute");
  
  const [ComponentToRender, setComponentToRender] = useState(null);
  useEffect(() => {
    if (!startupRoute|| startupRoute ==="/dashboard" ||  startupRoute === undefined) {
      const flowComponent = FnExecutorBasedOnFLow({
        AppoitmentFlowFn: () => <Dashboard />,
        DialysisFlowFn: () => <Queue />,
        ErefFlowFn: () => <Queue />,
      });
      setComponentToRender(flowComponent);
    } else {
      navigate(startupRoute, {
        state: {
          userId: userid,
          mobileNumber: phoneno,
          patientId: patientid,
        },
      });
    }
  }, [startupRoute, userid, phoneno, patientid]);

  return (
    <>
      {ComponentToRender}
    </>
  );
}
export default Dashboards
