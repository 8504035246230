import React, { useContext } from "react";
import actions from "../../redux/actions";
import { connect } from "react-redux";

import {
  Row,
  Div,
  Avatar,
  Text,
  Icons,
  Col,
  TextInput,
  Chip,
} from "qdm-component-library";
import { AppRoutes } from "../../router/routes";
import "./index.css";
import {
  getUserInfo,
  keySelection,
  calculateAge,
  getImgUrl,
  makeName,
  urls,
  nameDetailsGiver,
  AlertProps,
  _calculateAge,
} from "../../utils";

import SearchHospitalResult, {
  handleSelectHospital,
} from "./searchHospitalResult";
import moment from "moment";
import { useState } from "react";
import { UIColor } from "../../themes/theme";
import { LocationParent } from "./location/index";
import ChoosememberSearchResult from "./chooseMember";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBySpeciality from "./searchBySpeciality";
import { AlertContext } from "../../context";

function DoctorConsultation(props) {
  const location = useLocation();
  const alert = useContext(AlertContext);

  console.log("DoctorConsultation", location);
  console.count("DoctorConsultation");
  const { values } = props;
  const node = React.useRef();
  const navigate = useNavigate();
  // const location = {
  //   state: {
  //     userId: 1400,
  //     mobileNumber: "+911188667788",
  //     patientId: 1114,
  //     mobileNo: "+911188667788",
  //   },
  // };
  const dispatch = useDispatch();
  //const AlertContext = useContext()
  const [state, setState] = useState({
    loggedInState: values?.selectedUser
      ? values?.selectedUser
      : {
          name: "",
          email: "",
          userId: 0,
          birthDay: "",
          gender: "",
        },
    doctor_consultation_cdlc2: props?.indexToShow === 2 ? true : null,
    symptoms: props?.indexToShow === 3 ? true : null,
    search_hospital: props?.indexToShow === 4 ? true : null,
    select_city: values ? values?.cityName : 0,
    select_city_name: values?.location || {
      address: "",
      latitude: 0,
      longitude: 0,
      city: "",
      loaction: "",
    },
    select_symptoms: values ? values?.symptoms ?? [] : [],
    select_spccialities: values ? values?.specialities : [],
    select_doctors: values?.doctors ? values?.doctors : [],
    select_hospitsl: values ? values?.hospital : [],
    search_hospital_value: "",
    doctor_consultation_choosemember: props?.indexToShow === 1 ? true : null,
    symptomsSearch: "",
    select_symptomsSearch: values ? values?.symptomsSearch : [],
    symptomsDataState: [],
    allSymptomData: [],
    anchorEl: null,
    choosemember: [],
    symptomsData: [],
    specialtyData: [],
  });
  // end

  const changeState = (key, value) => {
    setState((prevstate) => ({
      ...prevstate,
      [key]: value,
    }));
  };

  const onclick_cdlc1 = (e, info) => {
    if (info && info.name) {
      setState((prevstate) => ({
        ...prevstate,
        doctor_consultation_choosemember:
          !prevstate.doctor_consultation_choosemember,
        symptoms: null,
        search_hospital: null,
        doctor_consultation_cdlc2: null,
        loggedInState: info,
      }));
    } else {
      setState((prevstate) => ({
        ...prevstate,
        doctor_consultation_choosemember:
          !prevstate.doctor_consultation_choosemember,
        symptoms: null,
        search_hospital: null,
        doctor_consultation_cdlc2: null,
      }));
    }
  };

  const onclick_cdlc2 = (e) => {
    // ;
    setState((prevstate) => ({
      ...prevstate,
      doctor_consultation_cdlc2: true,
      symptoms: null,
      search_hospital: null,
      doctor_consultation_choosemember: null,
    }));
  };

  const onclick_cdlc3 = (e) => {
    //  ;
    setState((prevstate) => ({
      ...prevstate,
      symptoms: true,
      doctor_consultation_cdlc2: null,
      search_hospital: null,

      doctor_consultation_choosemember: null,
    }));
  };

  const onclick_cdlc4 = (e) => {
    //  ;
    setState((prevstate) => ({
      ...prevstate,
      symptoms: null,
      doctor_consultation_cdlc2: null,
      search_hospital: !state.search_hospital,
      doctor_consultation_choosemember: null,
    }));
  };

  const onSeachClicked = (e) => {
    if (props.hideBackground) {
      if (
        state.select_hospitsl?.length > 0 &&
        (!state?.select_doctors?.[0]?.value
          ? state.select_spccialities?.length > 0
          : true)
      ) {
        const {
          select_hospitsl: hospital,
          select_spccialities: specialities,
          location,
          select_doctors,
        } = state;
        props.onValueChange({
          hospital: hospital?.map((l) => l?.value),
          specialities: specialities.map((spec) => spec.value),
          latitude: location?.latitude || 0,
          longtitude: location?.longitude || 0,
          doctorId: select_doctors?.[0]?.value,
        });
        props.onHeaderClicked();
      } else {
        let mandFields = [];
        let errMsg = "Please fill all the mandatory fields";
        if (state.select_hospitsl?.length === 0) {
          mandFields.push("hospital");
        }
        if (
          state.select_doctors.length === 0 &&
          state.select_spccialities.length === 0
        ) {
          mandFields.push("specialty");
        }
        if (mandFields.length > 0) {
          errMsg = `Please choose a ${mandFields.join(" and ")}`;
        }
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: errMsg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    }
    if (
      state.select_hospitsl?.length > 0 &&
      (!state?.select_doctors?.[0]?.value
        ? state.select_spccialities?.length > 0
        : true)
    ) {
      const {
        select_city,
        select_city_name,
        select_hospitsl,
        select_spccialities,
        select_symptoms,
        select_doctors,
        select_symptomsSearch,
        loggedInState,
      } = state;
      console.log("nextpageprops", {
        cityName: select_city,
        location: select_city_name,
        hospital: select_hospitsl,
        specialities: select_spccialities,
        symptoms: select_symptoms,
        doctors: select_doctors,
        symptomsSearch: select_symptomsSearch,
        selectedUser: state.loggedInState,
        userNo: state.loggedInState?.mobile,
        userId: state?.loggedInState?.userId,
        mobileNo: state.loggedInState?.mobile,
        appointmentId: location?.state?.appointmentId,
        patientId: location?.state?.patientId,
        walkin: true,
        // walkin: location?.state?.walkin
        unregister: location?.state?.unregister,
        age: _calculateAge(
          new Date(moment(location?.state?.unregister?.dob, "DD/MM/YYYY"))
        ),
      });
      navigate(AppRoutes?.appointmentBooking, {
        state: {
          cityName: select_city,
          location: select_city_name,
          hospital: select_hospitsl,
          specialities: select_spccialities,
          symptoms: select_symptoms,
          doctors: select_doctors,
          symptomsSearch: select_symptomsSearch,
          selectedUser: state.loggedInState,
          userNo: state.loggedInState?.mobile,
          userId: state?.loggedInState?.userId,
          mobileNo: state.loggedInState?.mobile,
          appointmentId: location?.state?.appointmentId,
          patientId: location?.state?.patientDetails?._id,
          facilityid: location?.state?.patientDetails?.facilityid,
          walkin: true,
          // walkin: location?.state?.walkin
          unregister: location?.state?.unregister,
          age: _calculateAge(
            new Date(moment(location?.state?.unregister?.dob, "DD/MM/YYYY"))
          ),
          apptypecode: "CodingMaster/12718",
        },
      });
    } else {
      let mandFields = [];
      let errMsg = "Please fill all the mandatory field";
      if (state.select_hospitsl?.length === 0) {
        mandFields.push("hospital");
      }
      if (
        state.select_doctors.length === 0 &&
        state.select_spccialities.length === 0
      ) {
        mandFields.push("specialty");
      }
      if (mandFields.length > 0) {
        errMsg = `Please choose a ${mandFields.join(" and ")}`;
      }
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  };

  const getchoosemember = async (userid, info) => {
    if (!info?.[0]?.[0]?.names) {
      return;
    }
    const {
      names = [],
      birthDay,
      gender,
      username,
      telecom,
      photo,
    } = info?.[0]?.[0];
    const user = [
      {
        name: [
          {
            given: makeName(names?.[0]) || "",
          },
        ],
        telecom,
        PersonID: userid,
        person: [
          {
            birthDay: birthDay,
            gender: gender,
            username: username || "",
            photo,
          },
        ],
        birthDate: birthDay,
        gender: gender,
        photo,
      },
    ];
    let loggedInState = {
      name: makeName(names?.[0]) || "",
      email:
        telecom?.find((val) => val.system === "Email" && val.use === "Work")
          ?.value || "",
      userId: userid,
      birthDay: birthDay,
      gender: gender,
      fileid: photo?.[0]?.fileid,
      mobile: telecom?.find(
        (val) => val.system === "Phone" && val.use === "Mobile"
      )?.value,
    };
    const payload = {
      userid,
    };
    if (props.GET_RELATED_PERSON) {
      if (userid) {
        const data = await props.GET_RELATED_PERSON(payload);
        if (data?.payload?.data) {
          setState((prevstate) => ({
            ...prevstate,
            choosemember: [...user, ...data?.payload?.data],
            loggedInState,
          }));
        }
      }
    }
  };
  React.useEffect(() => {
    // fetchUserInfo(dispatch);
    getInfos();
    // document.addEventListener("mousedown", handleClick);
    return () => {
      document.addEventListener("mousedown", handleClick);
    };
  }, []);
  console.log(urls.appointment_search_hospital);
  React.useEffect(() => {
    // setState({
    //   ...state,
    //   symptoms: props?.indexToShow === 3 ? true : null,
    //   doctor_consultation_cdlc2: props?.indexToShow === 2 ? true : null,
    //   search_hospital: props?.indexToShow === 4 ? true : null,
    //   doctor_consultation_choosemember:
    //     props?.indexToShow === 1 &&
    //     !(
    //       props?.guestInfo?.data?.type === 0 ||
    //       props?.guestInfo?.data?.type === 1
    //     )
    //       ? true
    //       : null,
    // });
    console.count("state");
  }, [props?.indexToShow]);

  //detect outside click
  const handleClick = (e) => {
    //  ;
    if (node && !node?.current?.contains(e.target)) {
      setState((prevstate) => ({
        ...prevstate,
        symptoms: null,
        doctor_consultation_cdlc2: null,
        search_hospital: null,
        doctor_consultation_choosemember: null,
      }));

      console.log("you just clicked outside of box");
    }
  };

  async function getInfos() {
    if (location?.state?.modifyUser) {
      return;
    }
    if (!location?.state?.patientDetails?.patientdetails?.telecom) {
      window.location.href = "/";
      return;
    }
    const info = getUserInfo();
    if (info.type === "Token") {
      let payload = {
        searchkey: props.values?.symptoms ?? "",
        roleid: "",
        facilityid: location?.state?.patientDetails?.facilityid || "",
      };
      let symptom_speciality_doctor_data = await dispatch(
        actions.SYMPTOM_SPECIALITY_DOCTOR_SEARCH_CALL(payload)
      );
      symptom_speciality_doctor_data =
        symptom_speciality_doctor_data?.payload?.data?.[0];

      let doctorData = symptom_speciality_doctor_data?.Doctor ?? [];
      let specialtyData = symptom_speciality_doctor_data?.Specialty ?? [];
      let symptomsData = symptom_speciality_doctor_data?.Symptoms ?? [];
      console.log(doctorData, specialtyData, symptomsData);
      //FIXME -
      const hospitalList = await dispatch(
        actions.HOSPITAL_SEARCH_CALL({
          roleid: "",
          facilityid: "",
          specialityid: [],
          city: values?.location?.city || state?.select_city_name?.city,
          practitionerid:
            values?.doctors?.[0]?.value ||
            state.select_doctors?.[0]?.value ||
            "",
        })
      );
      console.log(
        "tell",
        symptom_speciality_doctor_data,
        symptom_speciality_doctor_data,
        hospitalList
      );

      const searchData = doctorData.concat(specialtyData, symptomsData);
      console.log({ searchData });

      let loggedinPersonDetails = location?.state?.patientDetails;
      let patientData = nameDetailsGiver(loggedinPersonDetails?.patientdetails);
      let nextOfKinData = nameDetailsGiver(loggedinPersonDetails?.nextofkin);
      let choosemember = patientData.concat(nextOfKinData);
      let loggedInState = {
        name: makeName(patientData?.[0]?.name?.[0]),
        email:
          patientData?.[0]?.telecom?.find(
            (val) => val?.system?.display === "Email"
            //|| val?.use?.display === "Home"
          )?.value || "",
        userId: patientData?.[0]?.PersonID,
        birthDay: patientData?.[0]?.birthDate,
        gender: patientData?.[0]?.gender,
        fileid: patientData?.[0]?.photo?.[0]?.fileid,
        mobile:
          patientData?.[0]?.telecom?.find(
            (val) => val?.system?.display === "Phone"
            // ||  val?.use?.display === "Billing"
          )?.value || "",
        age: patientData?.[0]?.age,
      };
      console.log("useeffectsettingofselecteduser", loggedInState);
      setState((prevstate) => ({
        ...prevstate,
        symptomsDataState: searchData,
        allSymptomData: searchData,
        symptomsData: symptomsData,
        specialtyData: specialtyData,
        symptom_speciality_doctor_all_data: symptom_speciality_doctor_data,
        choosemember,
        nextOfKinData,
        patientData,
        loggedInState,
      }));
    }
  }

  console.log("state", state);
  const onChange = (v, s, x) => {
    setState((prevstate) => ({
      ...prevstate,
      select_city_name: v,
      select_hospitsl: [],
      search_hospital_value: "",
      doctor_consultation_cdlc2: true,
      symptoms: null,
      search_hospital: null,
      doctor_consultation_choosemember: null,
    }));
    GetAllHospitals(v);
  };

  const GetAllHospitals = (location) => {
    if (typeof location === "object") {
      dispatch(
        actions.HOSPITAL_SEARCH_CALL({
          roleid: "",
          facilityid: "",
          specialityid: [],
          city: location.city,
          practitionerid: "",
        })
      );
    }
  };

  const GetAllHospitalsAgainstDoctor = (id) => {
    dispatch(
      actions.HOSPITAL_SEARCH_CALL({
        roleid: "",
        facilityid: "",
        specialityid: [],
        city: state?.select_city_name?.city,
        practitionerid: id,
      })
    );
  };

  //  handleChange value for Doctor Consultation search
  const handlesearchActives = (name, val) => {
    // const state = state;
    let extra = {};
    if (name === "select_doctors") {
      extra = { select_hospitsl: [] };
    }
    setState({
      ...state,
      [name]: val,
      doctor_consultation_cdlc2:
        name === "select_city_name" ? null : state.doctor_consultation_cdlc2,
      // search_hospital:
      //   name === "select_hospitsl" ? null : state.search_hospital,
      symptomsSearch:
        name === "select_symptomsSearch" ? "" : state.symptomsSearch,
      // search_hospital_value:
      //   name === "select_hospitsl" ? val.label : state.search_hospital_value,
    });
    if (name === "select_doctors") {
      GetAllHospitalsAgainstDoctor(val[0]?.value);
    }
  };
  //   end
  //   handle delete value for Doctor Consultation Search by specialty symptoms disease treatment & doctor
  const ondeleteSpecialtySymptomsDiseaseDoctor = (_c) => {
    // eslint-disable-next-line
    const select_symptomsdata = state.select_symptoms.filter((l) => {
      if (l.value !== _c.value) return l;
    });
    // eslint-disable-next-line
    const select_spccialitiesdata = state.select_spccialities.filter((l) => {
      if (l.value !== _c.value) return l;
    });
    // eslint-disable-next-line
    const select_doctorsData = state.select_doctors.filter((l) => {
      if (l.value !== _c.value) return l;
    });

    const select_symptomsSearchData = state.select_symptomsSearch.filter(
      (l) => l !== _c.value
    );

    if (_c?.type === "Doctor") {
      GetAllHospitalsAgainstDoctor();
    }

    setState(
      (prevstate) => ({
        ...prevstate,
        select_symptoms: select_symptomsdata,
        select_spccialities: select_spccialitiesdata,
        select_symptomsSearch: select_symptomsSearchData,
        select_doctors: select_doctorsData,
        select_hospitsl: _c?.type === "Doctor" ? [] : state?.select_hospitsl,
      }),
      () => {
        const arrVal = state?.select_symptoms?.map((a) => a.label) || [];
        if (props.GET_ALL_SPECIALITY && props.GET_SPECIALITY) {
          if (arrVal?.length > 0) {
            props.GET_SPECIALITY({ speciality: arrVal }).then((spec) => {
              const notSpecialities = (
                this?.state?.allSymptomData || []
              ).filter((val) => {
                return val.type !== "Speciality";
              });
              const newSpecialities =
                this?.state?.select_spccialities?.filter?.((d) => {
                  const index = spec?.payload?.data?.findIndex?.(
                    (dd) => dd.value === d.value
                  );
                  return index !== -1;
                });
              const newSymptoms = [
                ...(spec?.payload?.data || []),
                ...notSpecialities,
              ];
              handlesearchActives("select_spccialities", [...newSpecialities]);
              changeState("allSymptomData", newSymptoms);
            });
          } else {
            handlesearchActives("select_spccialities", []);
            props.GET_ALL_SPECIALITY();
          }
        }
        onclick_cdlc3();
      }
    );
  };
  //   end

  const onDeleteHospital = (val) => {
    const filteredData = state?.select_hospitsl?.filter(
      (l) => l?.value !== val?.value
    );
    changeState("select_hospitsl", filteredData);
    onclick_cdlc4();
  };

  //   handle delete all value for Doctor Consultation Search by specialty symptoms disease treatment & doctor
  const ondeleteallSpecialtySymptomsDiseaseDoctor = () => {
    setState(
      (prevstate) => ({
        ...prevstate,
        select_symptoms: [],
        select_spccialities: [],
        select_symptomsSearch: [],
        select_doctors: [],
        specialtyData: prevstate?.symptom_speciality_doctor_all_data.Specialty,
      })
      // () => {
      //   if (props.GET_ALL_SPECIALITY) {
      //     props.GET_ALL_SPECIALITY();
      //   }
      //   if (props.PRACTITIONER_AGAINST_ORGANIZATION) {
      //     GetAllHospitalsAgainstDoctor();
      //   }
      // }
    );
  };

  const handleSymptomsData = (val) => {
    const filteredData = state.allSymptomData.filter((data) => {
      if (state.select_symptomsSearch?.indexOf(data.value) !== -1) {
        return false;
      }
      if (data?.label?.toLowerCase()?.includes(val?.toLowerCase() ?? "")) {
        return true;
      }
      return false;
    });
    // const filteredData = state.allSymptomData && state.allSymptomData?.filter(l=>state.select_symptomsSearch?.indexOf(l) === -1)?.filter(m=>m?.label?.toLowerCase()?.includes(val?.toLowerCase() ?? ''));
    setState((prevstate) => ({
      ...prevstate,
      symptomsSearch: val,
      symptomsDataState: filteredData ?? [],
    }));
  };

  const onKeyDownSpeciality = (e, wrapper, selectionName, classlist) => {
    if (state?.symptomsSearch && state?.symptomsDataState?.length > 0) {
      keyDownWrapper(e, wrapper, selectionName, classlist, "specality");
    }
  };

  const onKeyDownHospital = (e, wrapper, selectionName, classlist) => {
    const hospitalSelection = state?.select_doctors?.[0]?.value
      ? props?.getAllHospitalsAgainstDoctor
      : props?.hospitals;
    if (
      hospitalSelection?.data?.filter((m) =>
        m?.label
          ?.toLowerCase()
          ?.includes(state?.search_hospital_value?.toLowerCase() ?? "")
      )?.length > 0
    ) {
      keyDownWrapper(e, wrapper, selectionName, classlist, "hospital");
    }
  };

  //up arrow and down arrow to select
  const keyDownWrapper = (e, wrapper, selectionName, classlist, label) => {
    let divWrap = document.getElementsByClassName(wrapper)[0];
    if (e.keyCode === 40) {
      //up arrow to select
      keySelection(divWrap, "next", selectionName, classlist);
    } else if (e.keyCode === 38) {
      //down arrow to select
      keySelection(divWrap, "previous", selectionName, classlist);
    } else if (e.keyCode === 13) {
      //enter to select
      let index = [
        ...divWrap?.getElementsByClassName(selectionName)[0].parentElement
          .children,
      ].indexOf(divWrap?.getElementsByClassName(selectionName)[0]);
      if (label === "specality" && index > -1) {
        handleSymptomsData(state.symptomsDataState[index], {
          ...props,
          values: state,
          changeState: changeState,
          handlesearchActives: handlesearchActives,
        });
      } else if (label === "hospital" && index > -1) {
        const hospitalSelection = state?.select_doctors?.[0]?.value
          ? props?.getAllHospitalsAgainstDoctor
          : props?.hospitals;
        let hospital =
          hospitalSelection?.data?.length > 0 &&
          hospitalSelection?.data?.filter((m) =>
            m?.label
              ?.toLowerCase()
              ?.includes(state?.search_hospital_value?.toLowerCase() ?? "")
          )?.length > 0 &&
          hospitalSelection?.data?.filter((m) =>
            m?.label
              ?.toLowerCase()
              ?.includes(state?.search_hospital_value?.toLowerCase() ?? "")
          )?.[index];

        handleSelectHospital(
          {
            label: hospital.name ? hospital.name : hospital.label,
            value: hospital._id ? hospital._id : hospital.value,
          },
          {
            ...props,
            values: state,
            handlesearchActives: handlesearchActives,
          }
        );
      }
    }
  };

  const handleSpecialityBasedOnSymptom = (specality) => {
    setState((prevstate) => ({ ...prevstate, specialtyData: specality }));
  };

  //   end

  //   state declare
  const {
    select_city_name,
    select_spccialities,
    select_symptoms,
    select_symptomsSearch,
    select_doctors,
    select_hospitsl,
  } = state;
  //   end
  const name_ = state?.patientData?.[0]?.name?.[0]?.given;
  console.log(state, name_);
  return (
    <div id="doctor_consultation_parent_div" style={{ width: "100%" }}>
      <Row id="doctor_consultation_body_parent_row" key={"0"}>
        <Col id="doctor_consultation_body_parent_col" key={"0"}>
          <Div
            id="doctor_consultation_body_parent_div"
            key={"0"}
            inLineStyles="[object Object]"
            className="qdm"
            style={{
              backgroundColor: "#F9F9F9",
              height: "72px",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "36px",
              marginBottom: "",
              marginTop: "0px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "8px",
              width: "992px",
            }}
          >
            <Div
              id="doctor_consultation_body_guest_div"
              key={"0"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingLeft: "8px",
                paddingRight: "16px",
                width: "160px",
                cursor:
                  props?.guestInfo?.data?.type === 0 ||
                  props?.guestInfo?.data?.type === 1
                    ? "auto"
                    : "pointer",
                borderRadius: state.doctor_consultation_choosemember
                  ? "100px"
                  : "unset",
                backgroundColor: state.doctor_consultation_choosemember
                  ? "#ffffff"
                  : "unset",
                boxShadow: state.doctor_consultation_choosemember
                  ? "3px 3px 18px #0000001A"
                  : "unset",
              }}
              onClick={(e) =>
                !(
                  props?.guestInfo?.data?.type === 0 ||
                  props?.guestInfo?.data?.type === 1
                ) && onclick_cdlc1(e)
              }
            >
              <Avatar
                id="doctor_consultation_body_guest_avatar"
                key={"0"}
                variant="circle"
                letter={
                  state?.loggedInState?.name ||
                  name_ ||
                  props?.guestInfo?.data?.name ||
                  "Guest"
                }
                src={getImgUrl(state?.loggedInState?.fileid)}
                alt="Image"
                style={{
                  width: "32px",
                  height: "32px",
                  backgroundColor: "#E0E0E0",
                  fontSize: "14px",
                  fontFamily: "pc_medium",
                  color: "#101010",
                  marginLeft: "8px",
                }}
              ></Avatar>

              <Text
                id="doctor_consultation_body_guest_text"
                className="pc_regular"
                key={"1"}
                name={
                  state?.loggedInState?.name ||
                  name_ ||
                  props?.guestInfo?.data?.name ||
                  "Guest"
                }
                style={{
                  width: "90px",
                  color: "#101010",
                  fontSize: "14px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "noWrap",
                  letterSpacing: 0,
                }}
              ></Text>
            </Div>
            {/* {state.doctor_consultation_choosemember ||
                      state.doctor_consultation_cdlc2 ? null : ( */}
            <div
              id="doctor_consultation_choose_div"
              style={{
                width: "1px",
                height: "32px",
                border: `1px solid ${
                  state.doctor_consultation_choosemember ||
                  state.doctor_consultation_cdlc2
                    ? "transparent"
                    : "#e0e0e0"
                }`,
                display: "inline-block",
              }}
            ></div>
            {/* )} */}
            <Div
              id="doctor_consultation_location_parent_div"
              key={"1"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                height: "100%",
                borderRadius: state.doctor_consultation_cdlc2
                  ? "100px"
                  : "unset",
                backgroundColor: state.doctor_consultation_cdlc2
                  ? "#ffffff"
                  : "unset",
                boxShadow: state.doctor_consultation_cdlc2
                  ? "3px 3px 18px #0000001A"
                  : "unset",
                marginTop: "",
                paddingTop: "6px",
                paddingRight: "16px",
                paddingLeft: "16px",
              }}
              onClick={(e) => onclick_cdlc2(e)}
            >
              <LocationParent
                parent_id={'"doctor_consultation_location'}
                value={select_city_name}
                label=""
                active={state.doctor_consultation_cdlc2}
                placeholder={"City / Locality"}
                handleChange={(value) => onChange(value)}
                // isReadonly={isReadyOnly}
                // isrequired={isrequired}
              />
              {/* <TextInput
                      key={'0'}
                      type='text'
                      name='text'
                      variant='none'
                      helperTextType='error'
                      label=''
                      placeholder='City / Locality'
                      autoFocus={false}
                      autoComplete='off'
                      search={false}
                      borderColor='#9b9b9b'
                      value={select_city_name}
                      padding='10'
                      margin='0'
                      elevation='0'
                      style={{
                        borderWidth: '',
                        borderRadius: state.doctor_consultation_cdlc2
                          ? '24px'
                          : 'unset',
                        background: state.doctor_consultation_cdlc2
                          ? 'unset'
                          : 'transparent',
                        marginLeft: '8px'
                      }}
                    ></TextInput> */}
            </Div>
            {/* {state.doctor_consultation_cdlc2 ||
                      state.symptoms ? null : ( */}
            <div
              id="doctor_consultation_location_symptoms_div"
              style={{
                width: "1px",
                height: "32px",
                border: `1px solid ${
                  state.doctor_consultation_cdlc2 || state.symptoms
                    ? "transparent"
                    : "#e0e0e0"
                }`,
                display: "inline-block",
              }}
            ></div>
            {/* )} */}
            <Div
              id="doctor_consultation_symptoms_parent_div"
              key={"2"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                height: "100%",
                width: "440px",
                borderRadius: state.symptoms ? "100px" : "unset",
                backgroundColor: state.symptoms ? "#ffffff" : "unset",
                boxShadow: state.symptoms ? "3px 3px 18px #0000001A" : "unset",
                marginTop: "",
                paddingTop: "",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "16px 16px",
                overflow: "hidden",
              }}
            >
              {/* search inside list out of  Search by specialty symptoms disease treatment & doctor data */}
              <div
                id="doctor_consultation_symptoms_sub_div"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {[
                  ...select_symptoms,
                  ...select_spccialities,
                  ...select_doctors,
                  ...select_symptomsSearch,
                ]?.length > 0 &&
                  [
                    ...select_symptoms,
                    ...select_spccialities,
                    ...select_doctors,
                    ...select_symptomsSearch,
                  ]
                    ?.filter((l, i) => i < 1)
                    ?.map((_c, i) => {
                      return (
                        <Chip
                          id={`doctor_consultation_symptoms_chip_${i}`}
                          className="pc_regular"
                          onClick={() =>
                            ondeleteSpecialtySymptomsDiseaseDoctor(_c)
                          }
                          key={i}
                          variant="circle"
                          name={_c?.label}
                          style={{
                            borderColor:
                              UIColor.differentiationBackground.color,
                            color: UIColor.secondaryText.color,
                            borderRadius: "8px",
                            marginBottom: "0px",
                            padding: "2px 12px",
                            fontSize: 14,
                            backgroundColor:
                              UIColor.differentiationBackground.color,
                            // marginLeft: "16px"
                          }}
                          id={"Chip_assemble" + i}
                          closeIcon={
                            <Icons
                              id={`doctor_consultation_symptoms_icons_${i}`}
                              key={"1"}
                              fontIcon="close"
                              ariaHidden="true"
                              className=""
                              size="small"
                              style={{
                                fontWeight: 100,
                                "-webkitTextStroke": "1.4px white",
                                transform: "scale(1) translate(0px, 0px)",
                              }}
                            ></Icons>
                          }
                        ></Chip>
                      );
                    })}
                {[...select_symptoms, ...select_spccialities, ...select_doctors]
                  ?.length > 0 &&
                  [
                    ...select_symptoms,
                    ...select_spccialities,
                    ...select_doctors,
                  ]?.length > 1 && (
                    <div
                      id={`doctor_consultation_symptoms_more_div`}
                      className="pc_regular"
                      style={{
                        borderColor: UIColor.differentiationBackground.color,
                        color: UIColor.secondaryText.color,
                        marginRight: 7,
                        border: `1px solid ${UIColor.differentiationBackground.color}`,
                        borderRadius: 8,
                        padding: "3px 5px",
                        minWidth: "80px",
                        textAlign: "center",
                        fontSize: 14,
                        backgroundColor:
                          UIColor.differentiationBackground.color,
                      }}
                      id={"Chip_more"}
                    >
                      {"+"}
                      {[
                        ...select_symptoms,
                        ...select_spccialities,
                        ...select_doctors,
                      ]?.length - 1}
                      {" More"}
                    </div>
                  )}
              </div>
              {/* end */}
              <TextInput
                id={`doctor_consultation_symptoms_textinput`}
                onClick={(e) => onclick_cdlc3(e)}
                key={"0"}
                type="text"
                name="text"
                variant="none"
                helperTextType="error"
                label=""
                placeholder={`Search by specialty symptoms & doctor`}
                autoFocus={false}
                autoComplete="off"
                search={false}
                borderColor="#9b9b9b"
                padding="10"
                margin="0"
                elevation="0"
                className={state.symptoms ? "input_placeholder_color" : ""}
                value={state.symptomsSearch}
                onChange={(e) => handleSymptomsData(e.target.value)}
                style={{
                  borderWidth: "",
                  borderRadius: state.symptoms ? "24px" : "unset",
                  background: state.symptoms ? "unset" : "transparent",
                  fontSize: "14px",
                  padding: "0px",
                  fontFamily: "pc_regular",
                  color: "#101010",
                }}
                onKeyDown={(e) =>
                  onKeyDownSpeciality(
                    e,
                    "search_symptoms_input",
                    "keySelection",
                    "search_symptoms_input_options"
                  )
                }
              ></TextInput>
            </Div>
            {/* {state.symptoms ||
                      state.search_hospital ? null : ( */}
            <div
              id={`doctor_consultation_symptoms_hospital_div`}
              style={{
                width: "1px",
                height: "32px",
                border: `1px solid ${
                  state.symptoms || state.search_hospital
                    ? "transparent"
                    : "#e0e0e0"
                }`,
                display: "inline-block",
              }}
            ></div>
            {/* )} */}
            <Div
              id={`doctor_consultation_search_hospital_div`}
              key={"3"}
              onClick={(e) => onclick_cdlc4(e)}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                width: "185px",
                height: "100%",
                cursor: "pointer",
                boxShadow: state.search_hospital
                  ? "3px 3px 18px #0000001A"
                  : "unset",
                borderRadius: state.search_hospital ? "50px" : "unset",
                backgroundColor: state.search_hospital ? "#ffffff" : "unset",
                marginTop: "",
                paddingTop: "",
                paddingLeft: "16px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingRight: "16px",
              }}
            >
              <div
                id={`doctor_consultation_search_hospital_sub_div`}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {select_hospitsl?.length > 0 && (
                  <Chip
                    id={`doctor_consultation_search_hospital_chip`}
                    className="pc_regular"
                    onClick={() => onDeleteHospital(select_hospitsl?.[0])}
                    variant="circle"
                    name={
                      <p
                        style={{
                          width: 27,
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          margin: 0,
                        }}
                      >
                        {select_hospitsl?.[0]?.label}
                      </p>
                    }
                    style={{
                      borderColor: UIColor.differentiationBackground.color,
                      color: UIColor.primaryText.color,
                      backgroundColor: UIColor.differentiationBackground.color,
                      borderRadius: "8px",
                      marginBottom: "0px",
                      padding: "3px 12px",
                      // marginLeft: "16px"
                    }}
                    id={"Chip_hosp"}
                    closeIcon={
                      <Icons
                        id={`doctor_consultation_search_hospital_close_icons`}
                        key={"1"}
                        fontIcon="close"
                        ariaHidden="true"
                        className=""
                        size="small"
                        style={{
                          fontWeight: 100,
                          "-webkitTextStroke": "1.4px white",
                          transform: "scale(1) translate(0px, 0px)",
                        }}
                      ></Icons>
                    }
                  ></Chip>
                )}
                {select_hospitsl?.length > 1 && (
                  <div
                    id={`doctor_consultation_search_hospital_more_div`}
                    className="pc_medium"
                    style={{
                      color: UIColor.primaryText.color,
                      backgroundColor: UIColor.differentiationBackground.color,
                      marginRight: 7,
                      border: `1px solid ${UIColor.differentiationBackground.color}`,
                      borderRadius: 8,
                      padding: "3px 5px",
                      minWidth: "80px",
                      textAlign: "center",
                      fontSize: 13,
                    }}
                  >
                    {"+"}
                    {select_hospitsl?.length - 1}
                    {" More"}
                  </div>
                )}
              </div>
              <TextInput
                id={`doctor_consultation_search_hospital_value_textinput`}
                key={"0"}
                type="text"
                name="text"
                variant="none"
                helperTextType="error"
                label=""
                value={state.search_hospital_value}
                onChange={(e) =>
                  changeState("search_hospital_value", e.target.value)
                }
                onKeyDown={(e) =>
                  onKeyDownHospital(
                    e,
                    "hospital_listing",
                    "keySelection",
                    "hospital_listing_options"
                  )
                }
                placeholder={`Search hospital`}
                autoFocus={false}
                autoComplete="off"
                search={false}
                borderColor="#9b9b9b"
                padding="10"
                margin="0"
                elevation="0"
                style={{
                  borderWidth: "",
                  borderRadius: state.symptoms ? "24px" : "unset",
                  background: state.symptoms ? "unset" : "transparent",
                  paddingLeft: 0,
                  fontSize: "14px",
                  fontFamily: "pc_regular",
                }}
                className={
                  state.search_hospital ? "input_placeholder_color" : ""
                }
              ></TextInput>
            </Div>

            <Div
              id={`doctor_consultation_search_hospital_avatar_div`}
              key={"4"}
            >
              <Avatar
                id={`doctor_consultation_search_hospital_image`}
                key={"0"}
                onClick={onSeachClicked}
                variant="circle"
                letter="S"
                src="https://i.ibb.co/QD4fvNs/icons8-search.png"
                alt="Image"
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "0px",
                  marginTop: "",
                  backgroundColor: UIColor.secondaryColor.color,
                  padding: "16px",
                  cursor: "pointer",
                }}
              ></Avatar>
            </Div>
          </Div>
        </Col>

        <Col style={{ marginTop: "16px" }}>
          {state.doctor_consultation_choosemember && (
            <ChoosememberSearchResult
              parent_id={"doctor_consultation"}
              selectedId={state?.loggedInState?.userId}
              choosemember={state.choosemember}
              handlememberClick={onclick_cdlc1}
              refs={node}
              type={props?.guestInfo?.data?.type}
            />
          )}

          {state.symptoms && (
            <SearchBySpeciality
              parent_id={"doctor_consultation"}
              handlesearchActives={handlesearchActives}
              ondeleteSpecialtySymptomsDiseaseDoctor={
                ondeleteSpecialtySymptomsDiseaseDoctor
              }
              ondeleteallSpecialtySymptomsDiseaseDoctor={
                ondeleteallSpecialtySymptomsDiseaseDoctor
              }
              changeState={changeState}
              values={state}
              refs={node}
              specialityupdtae={handleSpecialityBasedOnSymptom}
              setState={setState}
            />
          )}
          {/* end */}
          {/* search_hospital */}
          {state.search_hospital && (
            <SearchHospitalResult
              parent_id={"doctor_consultation"}
              values={state}
              handlesearchActives={handlesearchActives}
              refs={node}
            />
          )}
          {/* end */}
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  cities: state?.searhcApiSlice?.cities,
  hospitals: state?.searhcApiSlice?.hospitals,
  guestInfo: state?.authSlice?.guestInfo,
  relatedPerson: state?.personApiSlice?.getRelatedPerson,
  getAllHospitalsAgainstDoctor:
    state?.searhcApiSlice?.getPractitionerAgainstOrganization,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
});

export default connect(mapStateToProps, actions)(DoctorConsultation);
