import React, { useState, useContext } from "react";
import Calendar from "../../../assets/img/svg/calendar.svg";
import warning from "../../../assets/img/svg/warning.svg";
import DotMenu from "../../../assets/img/svg/dot_menu.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { Button, Avatar, withStyles } from "@material-ui/core";
import { H6 } from "qdm-component-library";
import "./style.css";
import { AppRoutes } from "../../../router/routes";
import {
  getGlobalValueFrom_LclStrg_And_QryStrg,
  getImgUrl,
  makeName,
  TimeStampToMnthandDays,
  utcTOLocal,
} from "../../../utils";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch } from "react-redux";
//import { actions } from "primarycare-binder";
import actions from "../../../redux/actions";
import { Eye, Modify, CancelAppointment } from "../../../components/svg";
//import { Eye, Modify, CancelAppointment } from "../../../components";
import {
  QueueManagementContext,
  SetPatientDetailsContext,
} from "../../../context";
import RemoveIcon from "@material-ui/icons/Remove";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { UIColor } from "../../../themes/theme";
import { useLocation, useNavigate } from "react-router-dom";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DescriptionIcon from "@material-ui/icons/Description";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import reviseVisit from "../../../assets/img/svg/revisevisit.svg";
import viewVisit from "../../../assets/img/svg/viewvisit.svg";
import VisibilityIcon from '@material-ui/icons/Visibility';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EditIcon from '@material-ui/icons/Edit';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles((theme) => ({
  hover: {
    width: 35,
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: UIColor.primaryColor.color,
      "& svg path": {
        fill: "#fff",
      },
    },
  },
  vedioConsult: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
    marginRight: "10px",
    flex: 1,
    padding: "15px",
    // marginBottom: "8px",
    boxShadow: "none",
    borderTop: "1px solid #F0F0F0",
    backgroundColor: "#FFFFFF",
    borderRadius: "0px 0px 8px 8px",
  },
  icon: {
    fontSize: 16,
    marginRight: "4px",
  },
  boxStatus: {
    top: "-6.5%",
    position: "absolute",
    right: "4%",
    display: "flex",
    alignItems: "center",
    fontSize: "12px !important",
    zIndex: 1,
    backgroundColor: "#fff",
    margin: "0 2px",
  },
  removeIcon: {
    backgroundColor: "#6F6F6F",
    borderRadius: "50px",
    color: "#fff",
    fontSize: 12,
    marginRight: "4px",
  },

}));

function ViewCard(props) { 
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [encInfo, setEncInfo] = React.useState([])

  const setPatientDatacontext = useContext(SetPatientDetailsContext);
  const { displayData } = useContext(QueueManagementContext);

  const {
    openView = (id) => null,
    handlesidebarCancelbooking = (id) => null,
    handlesidebarCancelWatingListbooking = (id) => null,
    ProfileView = (id, val) => null,
    handleVisit = (id, val) => null,
    Billview = () => null,
    Receiptview = () => null,
    showBtn = false,
    isShowLabel = false,
    isFollowUp = false,
    val,
    ismodify,
    cancel,
    past = false,
    isBookNow,
    iscancel=true,
    showEncDetails,
    isModifyVisit = true,
    isViewBill = true,
    isViewReceipt = true,
    isViewVist = true,
    isViewEMR = true,
    isTransit =true,
    isViewApp=true,
    iscancelWaitinglist
  } = props;
  console.log(props,"=================");
  
  const id = val?.id || 0;
  let imgId = val?.resourcecode?.[0]?.photo?.[0]?.fileid || "";
  let speciality =
    val?.resourcecode?.[0]?.practitioner_role?.[0]?.Speciality || " ";
  const status = val?.status;
  const comment = val?.comment;
  const appstatus = val?.appstatus;
  //let name = val?.resourcecode?.shortdesc;
  let orgName = val?.orgid?.name;
  let facilityID = val?.facilityid;
  let sessionInfofacId = getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"})
  let name =
    val?.resourcecode?.[0]?.name?.[0]?.fulname || val?.doctorname ||
    process.env.REACT_APP_PATINET_DASHBOARD_LOCATION_FALLBACK_NAME;
  // if ((val?.resourcetype || "")?.toLowerCase() === "practitioner") {
  //  name = makeName(val?.resourcecode?.name?.[0] ?? {});
  // }
  if (isFollowUp) {
    name =
      val?.practitionerid?.[0]?.name?.[0]?.fulname ||
      process.env.REACT_APP_PATINET_DASHBOARD_LOCATION_FALLBACK_NAME;
    speciality = val?.practitionerid?.[0]?.practitioner_role?.[0]?.Speciality;
    imgId = val?.practitionerid?.[0]?.photo?.[0]?.fileid || "";
  }
  const consultation = val?.consultationModedisplay || "";
  const dateOfAppointment = utcTOLocal(val?.start, "DD MMM YY | hh:mm a");
  const watingListDateOfAppointment = utcTOLocal(val?.preferreddate, "DD MMM YY | hh:mm a");
  const follow_up_month_days = TimeStampToMnthandDays(val);

  const fetchAppInfo = async (id) => {
    debugger
    const data = await dispatch(
      actions.APPOINTMENT_READ({
        appointmentid: id,
        tenantid: "",
        facilityid: "",
      })


    );
    return data?.payload?.data || data?.AppointmentNotification || {};
  };
  const fetchWatingListAppInfo = async (id) => {
    const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);
    const data = await  dispatch(
      actions.WATING_LIST_APPOINMENTS({
        patientId: location?.state?.patientId,
        startDate: startEpochTime
      })
    );

    return data?.payload?.data
  };

  const modificationFun = async () => {
    // props.history.push(Routes.doctorListingWithIdCall+"123")
    // props.history.push(Routes.doctorListing)
    // setting the flag to true for modify function in confirm booking button
    setPatientDatacontext((prevstate) => ({
      ...prevstate,
      bookFlag: { modifyBook: true },
    }));
    const appInfo = JSON.parse(JSON.stringify(await fetchAppInfo(val._id)));
    appInfo["HealthcareService"] = appInfo["healthservice"];
    delete appInfo["healthservice"];
    console.log(appInfo,"appInfo");
    // props.history.push({
    navigate(AppRoutes.appointmentBooking, {
      state: {
        cityName: 0,
        location: {
          address: "Chennai, Tamil Nadu, India",
          latitude: 13.0826802,
          longitude: 80.2707184,
          city: "Chennai",
          location: "Chennai",
        },
        hospital: {
          label: "",
          value: appInfo?.orgid?.[0]?._id || 0,
          org_Id: appInfo?.orgid?.[0]?._id,
          orgType: appInfo?.orgid?.[0]?.OrgType,
        },
        specialities: [],
        symptoms: [
          // {
          //   label: "Fever",
          //   type: "Symptom",
          //   value: "SymptomsMapping/10000",
          // },
        ],
        selectedUser: {
          //name: appInfo?.Person?.[0]?.name?.[0]?.text ?? "",
          name: appInfo?.person?.[0]?.name?.[0]?.fulname,
          gender: appInfo?.person?.[0]?.gender ?? "",
          birthDay: appInfo?.person?.[0]?.birthdate,
          fileid: appInfo?.person?.[0]?.photo?.[0]?.fileid,
        },
        symptomsSearch: [],
        slotId: appInfo?.slotID?.[0],
        modifyUser: true,
        newBook: false,
        appointMentInfo: appInfo,
        patientId: location?.state?.patientId,
        userId: appInfo?.PersonID,
        appointmentId:val._id

        //unregister : { is_Unregister :  props.location.state.unregister}
      },
    });
  };
  const BookNow = async () => {
    setPatientDatacontext((prevstate) => ({
      ...prevstate,
      bookFlag: { modifyBook: true },
    }));
   const appInfo = JSON.parse(JSON.stringify(await fetchWatingListAppInfo(val._id)));
    appInfo["HealthcareService"] = appInfo["healthservice"];
    delete appInfo["healthservice"];
    // props.history.push({
    navigate(AppRoutes.appointmentBooking, {
      state: {
        cityName: 0,
        location: {
          address: "Chennai, Tamil Nadu, India",
          latitude: 13.0826802,
          longitude: 80.2707184,
          city: "Chennai",
          location: "Chennai",
        },
        hospital: {
          label: "",
          value: appInfo?.[0]?.orgid?._id || 0,
          org_Id: appInfo?.[0]?.orgid?._id,
          orgType: appInfo?.[0]?.orgid?.OrgType?.[0],
        },
        specialities: [],
        symptoms: [
          // {
          //   label: "Fever",
          //   type: "Symptom",
          //   value: "SymptomsMapping/10000",
          // },
        ],
        selectedUser: {
          //name: appInfo?.Person?.[0]?.name?.[0]?.text ?? "",
          name: appInfo?.[0]?.personid?.name,
          gender: appInfo?.[0]?.personid?.gender?.display ?? "",
          birthDay: appInfo?.[0]?.personid?.birthdate,
          fileid: appInfo?.[0]?.personid?.photo?.[0]?.fileid,
        },
        symptomsSearch: [],
        // slotId: appInfo?.slotID?.[0],
        modifyUser: true,
        newBook: true,
        appointMentInfo: appInfo?.[0],
        patientId: appInfo?.[0]?.patientid||location?.state?.patientId,
        userId: appInfo?.[0]?.personid?._id,
        appointmentReqId:val._id,
        resource_id:appInfo?.[0]?.doctor,
        resourceType:"",
        //unregister : { is_Unregister :  props.location.state.unregister}
      },
    });
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const idopen = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { parent_id, visitCard = false } = props;
  const getEnc = async () => {
    const appointmentData_encounter = await dispatch(
      actions.APPOINTMENT_ID_GET_ENCOUNTE_DETAILS({
        appointmentId: val?._id,
      })
    );
    setEncInfo(appointmentData_encounter?.payload?.data?.[0]);
  }
  const dateOfEnc = utcTOLocal(encInfo?.encounterdate, "DD MMM YY | hh:mm a");
  React.useEffect(() => {
    getEnc()
  }, [])
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: UIColor.differentiationBackground.color,
      color: UIColor.primaryText.color,
      boxShadow: theme.shadows[1],
      fontSize: 18,
    },
  }))(Tooltip);
  return (
    <div
      id={`${parent_id}_viewcard_parent_div`}
      style={{
        marginBottom: "3%",
        boxShadow: "none",
        border: `1px solid ${UIColor.lineBorderFill.color} `,
        backgroundColor: "#FFFFFF",
        minHeight: 133,
        // padding: "15px",
        borderRadius: 8,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {props?.canceled && (
        <H6
          className={`${classes.boxStatus} pc_medium`}
          id={`${props?.val._key}dashboard-CardList-${props?.title}-H6`}
          style={{
            // backgroundColor: "#fff",
            // color: status
            //   ? "#6F6F6F"
            //   : comment === "completed"
            //   ? "#3BB213"
            //   : "#FF4D4A",
            color:
              appstatus?.display === "cancelled"
                ? "#FF4D4A"
                : appstatus?.display === "DoctorSignoff"
                  ? "#3BB213"
                  : "#6F6F6F",
          }}
        >
          {/* {status ? (
            <>
              <RemoveIcon className={classes.removeIcon} /> IN COMPLETE
            </>
          ) : comment === "completed" ? (
            <>
              <CheckIcon className={classes.icon} /> COMPLETED
            </>
          ) : (
            <>
              <CancelIcon className={classes.icon} /> CANCELLED{" "}
            </>
          )} */}
          {appstatus?.display === "cancelled" ? (
            <>
              <CancelIcon className={classes.icon} /> CANCELLED{" "}
            </>
          ) : appstatus?.display === "DoctorSignoff" ? (
            <>
              <CheckIcon className={classes.icon} /> COMPLETED
            </>
          ) : (
            <>
              <RemoveIcon className={classes.removeIcon} /> IN COMPLETE
            </>
          )}
        </H6>
      )}
      {isShowLabel && (
        <H6
          className={`${classes.boxStatus} pc_medium`}
          id={`${props?.val._key}dashboard-CardList-${props?.title}-H6`}
        >
          {/* {status ? (
            <>
              <RemoveIcon className={classes.removeIcon} /> IN COMPLETE
            </>
          ) : comment === "completed" ? (
            <>
              <CheckIcon className={classes.icon} /> COMPLETED
            </>
          ) : (
            <>
              <CancelIcon className={classes.icon} /> CANCELLED{" "}
            </>
          )} */}
          {/*{appstatus?.display === "cancelled" ? (
            <>
              <CancelIcon className={classes.icon} /> CANCELLED{" "}
            </>
          ) : appstatus?.display === "DoctorSignoff" ? (
            <>
              <CheckIcon className={classes.icon} /> COMPLETED
            </>
          ) : (
            <>
              <RemoveIcon className={classes.removeIcon} /> IN COMPLETE
            </>
          )}*/}
          {
            val?.encstatus ? (
              (val?.encstatus === "Completed" || val?.encstatus === "Discharged" || val?.encstatus === "Billed") ? (
                <div style={{ color: UIColor.success.color, display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CheckIcon className={classes.icon} /> {val?.encstatus}
                </div>
              ) : (val?.encstatus === "Cancel") ? (
                <div style={{ color: UIColor.error.color, display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CancelIcon className={classes.icon} /> {val?.encstatus}
                </div>
              ) : (
                <div style={{ color: UIColor.primaryColor.color, display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <RemoveCircleIcon className={classes.icon} /> {val?.encstatus}
                </div>
              )
            ) : ("")
          }
        </H6>
      )}
      <Popover
        id={idopen}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography
          id={`${parent_id}_viewcard_label_typography`}
          className={clsx("viewDocText", "pc_regular")}
          style={{
            padding: 8,
            fontSize: 14,
            cursor: "pointer",
            color: "#101010",
          }}
          onClick={() =>
            navigate({
              // props.history.push({
              pathname: AppRoutes.viewreport,
              state: {
                // appNo: props?.val.appno,
                // created: props?.val.createddate,
                // report_docs: props?.val.report_docs,
                // data: props?.val,
                appNo: val?.appno,
                created: val?.createddate,
                report_docs: val?.report_docs,
                data: val,
              },
            })
          }
        >
          View Uploaded Report
        </Typography>
      </Popover>

      <div
        style={{
          display: "flex",
          marginBottom: "16px",
          padding: "15px 15px 0px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div
          id={`${parent_id}_viewcard_image_div`}
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          {/* <img
            id={`${parent_id}_viewcard_image`}
            alt="img"
            src="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
            style={{
              width: "100%",
              height: "auto",
            }}
          /> */}
          <Avatar
            src={getImgUrl(imgId) || undefined}
            id={`${parent_id}_viewcard_image`}
            style={{
              backgroundColor: UIColor.differentiationBackground.color,
              color: UIColor.primaryColor.color,
            }}
          >
            {
              (name ? name : "")
                ?.replaceAll("Mrs", "")
                ?.replaceAll("Mr", "")
                ?.replaceAll(".", "")[0]
            }
          </Avatar>
        </div>
        <div
          id={`${parent_id}_viewcard_speciality_div`}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "16px",
            flex: 1,
          }}
        >
          <div
            id={`${parent_id}_viewcard_speciality_sub_div`}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <H6
              id={`${parent_id}_viewcard_speciality_span`}
              style={{
                fontSize: "14px",
                color: UIColor.primaryText.color,
                letterSpacing: 0,
                lineHeight: 1,
                fontWeight: "600",
                margin: speciality ? "0px 0px 8px" : "0px",
              }}
              className="pc_medium"
            >
              {name ? name : ""}
            </H6>

            {orgName && (
              <H6
                id={`${parent_id}_viewcard_speciality_sub_span`}
                className="pc_regular"
                style={{
                  color: UIColor.tertiaryText.color,
                  fontSize: "12px",
                  letterSpacing: 0,
                  lineHeight: 1,
                }}
              >
                {orgName}
              </H6>
            )}

          </div>

          {!isFollowUp && (
            <div
              style={{ marginRight: "8px" }}
              id={`${parent_id}_viewcard_dotmenu_div`}
            >
              {props?.val?.report_docs?.length > 0 && (
                <img
                  id={`${parent_id}_viewcard_dotmenu_image`}
                  alt="img"
                  src={DotMenu}
                  style={{ cursor: "pointer" }}
                  onClick={handleClick}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div
        id={`${parent_id}_viewcard_sub_parent_div`}
        style={{
          display: "flex",
          padding: "0px 15px 15px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div
          id={`${parent_id}_viewcard_sub_parent_image_div`}
          style={{
            width: "40px",
            height: "40px",
            backgroundColor: UIColor.differentiationBackground.color,
            color: UIColor.primaryColor.color,
            borderRadius: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "16px",
          }}
        >
          {!isFollowUp ? (
            <img
              id={`${parent_id}_viewcard_calendar_image`}
              alt="img"
              src={Calendar}
            />
          ) : (
            <img
              id={`${parent_id}_viewcard_warning_image`}
              alt="img"
              src={warning}
            />
          )}
        </div>
        <div
          id={`${parent_id}_viewcard_appointment_div`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            flex: 1,
          }}
        >
          <div id={`${parent_id}_viewcard_followup_div`}>
            <H6
              id={`${parent_id}_viewcard_followup_span`}
              className="pc_medium"
              style={{
                fontSize: "14px",
                color: isFollowUp ? "#2A60BC" : "#101010",
                lineHeight: 1,
                letterSpacing: 0,
                margin: speciality ? "0px 0px 8px" : "0px",
              }}
            >
              {isFollowUp
                ? `After ${follow_up_month_days}`
                : val?.appno ? val?.appno : "Waiting List" + " | " + watingListDateOfAppointment ? watingListDateOfAppointment : dateOfAppointment}
            </H6>
            {encInfo?.encounter_no && (
            <H6
              id={`${parent_id}_viewcard_followup_span`}
              className="pc_medium"
              style={{
                fontSize: "14px",
                color: isFollowUp ? "#2A60BC" : "#101010",
                lineHeight: 1,
                letterSpacing: 0,
                margin: speciality ? "0px 0px 8px" : "0px",
              }}
            >
              {showEncDetails ? encInfo?.encounter_no + " | " + dateOfEnc : ""}
            </H6>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <H6
                id={`${parent_id}_viewcard_consultation_span`}
                className="pc_regular"
                style={{
                  color: UIColor.tertiaryText.color,
                  fontSize: "12px",
                  letterSpacing: 0,
                  lineHeight: 1,
                  margin: 0,
                }}
              >
                {Array.isArray(consultation)
                  ? consultation?.join()
                  : consultation}
              </H6>
              {props?.val?.encclass === "Transit" && (
                <H6
                  id={`${parent_id}_viewcard_consultation_span_encclass`}
                  className="pc_regular"
                  style={{
                    color: UIColor.tertiaryText.color,
                    fontSize: "12px",
                    letterSpacing: 0,
                    lineHeight: 1,
                    margin: 0,
                  }}
                >
                  &nbsp;|&nbsp;{props?.val?.encclass}
                </H6>
              )}
            </div>
          </div>

          <div
            style={{ display: "inherit" }}
            id={`${parent_id}_viewcard_view_div`}
          >
            {!showBtn && (
              props?.val?.appstatus?.display === "Transit" ? (
                <Typography
                  id={`${parent_id}_viewcard_encclass_typography`}
                  style={{
                    color: "#3C41A0",
                    padding: "4px 12px",
                    borderRadius: 4,
                    border: `1px solid ${"#3C41A0"}`,
                    marginLeft: "3px",
                    display: "inline-block",
                    textAlign: "center"
                  }}
                >
                  {props?.val?.appstatus?.display}
                </Typography>
              ) : (
               isViewApp&& <React.Fragment>
                {(!iscancelWaitinglist) &&
                  <LightTooltip
                    id={`${parent_id}_viewcard_view_title_tooltip`}
                    title={visitCard ? "View Visit" : "View Appointment"}
                    arrow
                    placement="top"
                  >
                    <div
                      id={`${parent_id}_viewcard_view_hover_div`}
                      data-tip="View"
                      className={classes.hover}
                      style={{
                        padding: "1px 6px",
                        borderRadius: "5px",
                        marginLeft: "3px",
                        cursor: "pointer",
                        outline: `1px solid ${UIColor.primaryColor.color}`
                      }}
                      onClick={() =>
                        visitCard
                          ? handlesidebarCancelbooking(val?._id, "view")
                          : openView(val?._id)
                      }
                    >
                      <VisibilityIcon
                        height="24"
                        width="24"
                        style={{
                          color: UIColor.primaryColor.color
                        }}
                      />
                    </div>
                  </LightTooltip>
                  }
                  <div
                    id={`${parent_id}_viewcard_title_Modify_Appointment_div`}
                    style={{
                      display: past || ismodify ? "none" : "unset",
                      width: "1px",
                      backgroundColor: "#E0E0E0",
                      marginLeft: "3px",
                      height: "20px",
                      marginTop: "6px",
                    }}
                  />
                  {(!iscancelWaitinglist &&facilityID !== sessionInfofacId && isTransit) &&
                    <LightTooltip
                      id={`${parent_id}_viewcard_title_Modify_Appointment_tooltip`}
                      title={"Transit Appointment"}
                      arrow
                      placement="top"
                    >
                      <div
                        id={`${parent_id}_viewcard_title_Modify_div`}
                        className={classes.hover}
                        style={{
                          padding: "1px 6px",
                          borderRadius: "5px",
                          marginLeft: "3px",
                          cursor: "pointer",
                          outline: `1px solid ${UIColor.primaryColor.color}`
                        }}
                        onClick={() => {
                          props?.handleMultiBookData({
                            ...props?.val,
                            isTransit: true,
                          });
                        }}
                      >
                        <TransferWithinAStationIcon
                          height="24"
                          width="24"
                          style={{
                            color: UIColor.primaryColor.color
                          }}
                        />
                      </div>
                    </LightTooltip>
                  }
                    {(!iscancelWaitinglist) &&
                    <LightTooltip
                      id={`${parent_id}_viewcard_title_Modify_Appointment_tooltip`}
                      title={visitCard ? "Revise Visit" : "Modify Appointment"}
                      arrow
                      placement="top"
                    >
                      <div
                        id={`${parent_id}_viewcard_title_Modify_div`}
                        className={classes.hover}
                        style={{
                          padding: "1px 6px",
                          borderRadius: "5px",
                          marginLeft: "3px",
                          cursor: "pointer",
                          outline: `1px solid ${UIColor.primaryColor.color}`
                        }}
                        onClick={() =>
                          visitCard
                            ? handlesidebarCancelbooking(val?.id, "modify")
                            : modificationFun()
                        }
                      >
                        {/* <img
                          id={`${parent_id}_viewcard_title_Modify_img`}
                          src={Modify} alt={"Modify"}  /> */}
                        <Modify id={`${parent_id}_viewcard_title_Modify_img`}
                         height="24"
                         width="24"
                         color={UIColor.primaryColor.color}
                        />
                      </div>
                    </LightTooltip>
                  }
                  {
                       (!iscancelWaitinglist && iscancel && facilityID === sessionInfofacId) &&
                    (
                      <LightTooltip
                        id={`${parent_id}_viewcard_title_Cancel_Appointment_tooltip`}
                        title={visitCard ? "Cancel Visit" : "Cancel Appointment"}
                        arrow
                        placement="top"
                      >
                        <div
                          id={`${parent_id}_viewcard_title_Cancel_Appointment_sub_div`}
                          className={classes.hover}
                          style={{
                            padding: "1px 6px",
                            borderRadius: "5px",
                            marginLeft: "3px",
                            cursor: "pointer",
                            outline: `1px solid ${UIColor.primaryColor.color}`
                          }}
                          onClick={() =>
                            handlesidebarCancelbooking(val?._id, "cancel")
                          }
                        >
                          <CancelAppointment
                            height="24"
                            width="24"
                            color={UIColor.primaryColor.color}
                          />
                        </div>
                      </LightTooltip>
                    )}
                     {
                       (iscancelWaitinglist && facilityID === sessionInfofacId) &&
                    (
                      <LightTooltip
                        id={`${parent_id}_viewcard_title_Cancel_Appointment_tooltip`}
                        title={ "Cancel From WaitList"}
                        arrow
                        placement="top"
                      >
                        <div
                          id={`${parent_id}_viewcard_title_Cancel_Appointment_sub_div`}
                          className={classes.hover}
                          style={{
                            padding: "1px 6px",
                            borderRadius: "5px",
                            marginLeft: "3px",
                            cursor: "pointer",
                            outline: `1px solid ${UIColor.primaryColor.color}`
                          }}
                          onClick={() =>
                            handlesidebarCancelWatingListbooking(val, "cancel")
                          }
                        >
                          <CancelAppointment
                            height="24"
                            width="24"
                            color={UIColor.primaryColor.color}
                          />
                        </div>
                      </LightTooltip>
                    )}
                    <div
                      style={{ position: "relative", minWidth: 96 }}
                      id={`${parent_id}_viewcard_comment_div`}
                    >
                      
                      {iscancelWaitinglist && 
                      <>
                      {/* <Button
                        id={`${parent_id}_viewcard_book_noe_button`}
                        variant="outlined"
                        color="primary"
                        className="pd-book-btn"
                        onClick={() => BookNow()}
                        style={{
                          color: "#01205C",
                          borderColor: "#DFE5EB",
                          borderRadius: 8,
                          padding: "6px 2px",
                          margin:5,
                          backgroundColor: "#E5E8EE",
                          textTransform: "capitalize"
                        }}
                      >
                        Book Again
                      </Button> */}
                      <LightTooltip
                        id={`${parent_id}_viewcard_title_Cancel_Appointment_tooltip`}
                        title={ "Book Now"}
                        arrow
                        placement="top"
                      >
                        <div
                          id={`${parent_id}_viewcard_title_Cancel_Appointment_sub_div`}
                          className={classes.hover}
                          style={{
                            padding: "1px 6px",
                            borderRadius: "5px",
                            marginLeft: "7px",
                            cursor: "pointer",
                            outline: `1px solid ${UIColor.primaryColor.color}`
                          }}
                          onClick={() =>
                            BookNow()
                          }
                        >
                          <DescriptionIcon
                            height="24"
                            width="24"
                            color={UIColor.primaryColor.color}
                          />
                        </div>
                      </LightTooltip>
                    </>
                    
                    }
                      
                    </div>
                </React.Fragment>
              )
            )}
            {showBtn && (
              <div
                style={{
                  position: "relative",
                  minWidth: 96,
                  display: "flex",
                  gap: "3px"
                }}
                id={`${parent_id}_viewcard_comment_div`}
              >
                { !props.isFollowUp && isViewEMR && props?.val?.appstatus?.display !== "Transit" &&
                  <LightTooltip
                    id={`${parent_id}_viewcard_view_title_tooltip`}
                    title={"View EMR"}
                    arrow
                    placement="top"
                  >
                    <div
                      id={`${parent_id}_viewcard_view_hover_div`}
                      data-tip="View"
                      className={classes.hover}
                      style={{
                        padding: "1px 6px",
                        borderRadius: "5px",
                        marginLeft: "3px",
                        cursor: "pointer",
                        outline: `1px solid ${UIColor.primaryColor.color}`
                      }}
                      onClick={() => ProfileView(val?._id, val)}
                    >
                      <EqualizerIcon
                        height="24"
                        width="24"
                        style={{
                          color: UIColor.primaryColor.color
                        }}
                      />
                    </div>
                  </LightTooltip>
                }
                {
                  isViewVist && props?.val?.appstatus?.display !== "Transit" &&
                  (<LightTooltip
                    id={`${parent_id}_viewcard_view_title_tooltip`}
                    title={"View Visit"}
                    arrow
                    placement="top"
                  >
                    <div
                      id={`${parent_id}_viewcard_view_hover_div`}
                      data-tip="View"
                      className={classes.hover}
                      style={{
                        padding: "1px 6px",
                        borderRadius: "5px",
                        marginLeft: "3px",
                        cursor: "pointer",
                        outline: `1px solid ${UIColor.primaryColor.color}`
                      }}
                      onClick={() => handleVisit(val, "view")}
                    >
                      <VisibilityIcon
                        height="24"
                        width="24"
                        style={{
                          color: UIColor.primaryColor.color
                        }}
                      />
                    </div>
                  </LightTooltip>
                  )
                }
                {isModifyVisit && props?.val?.appstatus?.display !== "Transit" && (
                  <LightTooltip
                    id={`${parent_id}_viewcard_view_title_tooltip`}
                    title={"Modify Visit"}
                    arrow
                    placement="top"
                  >
                    <div
                      id={`${parent_id}_viewcard_view_hover_div`}
                      data-tip="View"
                      className={classes.hover}
                      style={{
                        padding: "1px 6px",
                        borderRadius: "5px",
                        marginLeft: "3px",
                        cursor: "pointer",
                        outline: `1px solid ${UIColor.primaryColor.color}`
                      }}
                      onClick={() => handleVisit(val, "modify")}
                    >
                      <EditIcon
                        height="24"
                        width="24"
                        style={{
                          color: UIColor.primaryColor.color
                        }}
                      />
                    </div>
                  </LightTooltip>)}
                {isViewBill && <LightTooltip title={"View Bill"} arrow placement="top">
                  <div
                    id={`${parent_id}_viewcard_view_hover_div`}
                    data-tip="View"
                    className={classes.hover}
                    style={{
                      padding: "1px 6px",
                      borderRadius: "5px",
                      marginLeft: "3px",
                      cursor: "pointer",
                      outline: `1px solid ${UIColor.primaryColor.color}`
                    }}
                    onClick={() => Billview(props?.val?.appstatus?.display === "Transit",props?.val?.appstatus?.display === "Transit" && props?.val?.transappid ? props?.val?.transappid : val?._id, val)}
                  >
                    <AttachMoneyIcon
                      height="24"
                      width="24"
                      style={{
                        color: UIColor.primaryColor.color
                      }}
                    />
                  </div>
                </LightTooltip>}
                {isViewReceipt && props?.val?.appstatus?.display !== "Transit" && <LightTooltip title={"View Receipt"} arrow placement="top">
                  <div
                    id={`${parent_id}_viewcard_view_hover_div`}
                    data-tip="View"
                    className={classes.hover}
                    style={{
                      padding: "1px 6px",
                      borderRadius: "5px",
                      marginLeft: "3px",
                      cursor: "pointer",
                      outline: `1px solid ${UIColor.primaryColor.color}`
                    }}
                    onClick={() => Receiptview(val?._id, val)}
                  >
                    <ReceiptIcon
                      height="24"
                      width="24"
                      style={{
                        color: UIColor.primaryColor.color
                      }}
                    />
                  </div>
                </LightTooltip>}
              </div>)}
          </div>
        </div>
      </div>
      {consultation === "Video Consultation" && props?.upcoming && (
        <div className={classes.vedioConsult}>
          <H6
            className="pc_regular"
            style={{
              fontSize: 12,
              margin: "5px 0px",
              color: "#F58B00",
            }}
          >
            Passcode : {val?.avc?.passCode ?? "*******"}
          </H6>
          <Button
            variant="outlined"
            color="primary"
            className="pd-book-btn"
            onClick={() => {
              // props.history.push({
              navigate({
                pathname: AppRoutes.videoCall,
                state: {
                  appointmentId: val?._key,
                  name: props?.loggedUserInfo?.data?.name?.text,
                  role: props?.loggedUserInfo?.data?.role,
                  data: displayData?.[0],
                },
              });
            }}
            style={{
              color: "#0071F2",
              borderColor: "#0071F2",
              borderRadius: 8,
              padding: "4px 15px",
            }}
          >
            Join Now
          </Button>
        </div>
      )}
    </div>
  );
}

export default ViewCard;
