import { UIColor } from "../../../themes/theme";

const styles = {
  rowFlex: {
    display: "flex",
    alignItems: "center",
  },
  columnFlex: {
    display: "flex",
    flexDirection: "column",
  },
  h6: {
    fontSize: 14,
    color: "#101010",
    margin: 0,
  },
  span: {
    fontSize: 12,
    color: "#6F6F6F",
    margin: 0,
  },
  cardStyle: {
    boxShadow: "0px 10px 25px #0000000A",
    borderRadius: 8,
    padding: 10,
  },
  cardStyle1: {
    boxShadow: "0px 10px 25px #0000000A",
    borderRadius: 8,
    padding: 10,
    width: "200px",
  },
  avatar: {
    margin: 0,
    marginRight: 15,
    width: 48,
    height: 48,
    borderRadius: 8,
    backgroundColor: UIColor.differentiationBackground.color,
  },
  img: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  status: {
    borderRadius: 4,
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: 7,
    fontFamily: "pc_regular",
    fontSize: 12,
    color: UIColor.success.color,
    backgroundColor: "#3bb21329",
  },
  status1: {
    borderRadius: 4,
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: 7,
    fontFamily: "pc_regular",
    fontSize: 12,
    border: "1px solid #bd2413",
    color: "#bd2413",
  },
  status2: {
    borderRadius: 4,
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: 7,
    fontFamily: "pc_regular",
    fontSize: 12,
    border: "1px solid #F58B00",
    color: "#F58B00",
  },
  dot: {
    width: 6,
    height: 6,
    backgroundColor: UIColor.success.color,
    borderRadius: "50%",
    marginRight: "5px",
  },
  inactiveDot: {
    width: 6,
    height: 6,
    backgroundColor: "#bd2413",
    borderRadius: "50%",
    margin: "0 5px",
  },
  inHoldDot: {
    width: 6,
    height: 6,
    backgroundColor: "#F58B00",
    borderRadius: "50%",
    margin: "0 5px",
  },
  editIcon: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    padding: "6px 10px",
  },
};

export default styles;